.projects-overview div.welcome-message .message-box .loader-bar {
  position: relative;
  top: 2px;
  display: inline-block;
  width: 50px;
  height: 11px;
  background-image: url("../images/loader-bar.gif");
  background-position: center center;
  background-repeat: no-repeat;
}
.projects-overview #name {
  width: 90%;
}
.projects-overview li .caption {
  padding-right: 100px;
}
