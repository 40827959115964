/* @generated */ 
/* @generated */ 
.clearfix {
  height: 0;
  clear: both;
}
@font-face {
  font-family: 'gdt';
  src: url("../fonts/gdt.eot");
  src: url("../fonts/gdt.eot?#iefix") format('embedded-opentype'), url("../fonts/gdt.woff") format('woff'), url("../fonts/gdt.ttf") format('truetype'), url("../fonts/gdt.svg#gdt") format('svg');
  font-weight: normal;
  font-style: normal;
}
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  clear: both;
  display: table;
  content: " ";
}
.col-6 {
  width: 50%;
  position: relative;
  min-height: 1px;
  float: left;
}
.no-border {
  border-width: 0 !important;
}
.col-offset-6 {
  margin-left: 50%;
}
.pull-right {
  float: right;
}
button.save:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.save * {
  margin-left: 0.5rem;
}
button.edit:before {
  font-family: 'gdt';
  content: '\e012';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.edit * {
  margin-left: 0.5rem;
}
.presentations {
  font: normal 10pt 'Arial Narrow', Arial, sans-serif;
  position: relative;
  width: 100%;
  background: #fff;
  color: #333;
}
.presentations .outcomes-container table .active {
  background-color: #fff !important;
}
.presentations a {
  color: #333;
  text-decoration: none;
}
.presentations a:hover {
  color: #666;
}
.presentations a:hover,
.presentations a:active,
.presentations a:visited {
  text-decoration: none;
}
.presentations .main-outcomes label,
.presentations .main-outcomes .main-outcomes-content {
  float: left;
}
.presentations .main-outcomes label ul,
.presentations .main-outcomes .main-outcomes-content ul {
  margin-top: 0px;
}
.presentations .inserted .question-title {
  font-weight: bold;
  padding: 5px;
  border-top: solid 2px #000;
}
.presentations table {
  border-collapse: collapse;
  width: 100%;
  background: #dedede;
  border-spacing: 0px;
  text-align: left;
}
.presentations table thead {
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}
.presentations table thead td {
  border-bottom: 2px solid #000;
}
.presentations table thead tr {
  padding: 5px;
}
.presentations table thead,
.presentations table thead td,
.presentations table th {
  background: #fff;
}
.presentations table td {
  background: #fff;
}
.presentations table td p {
  color: #333;
}
.presentations table tbody td.control-label {
  font-weight: bold;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.presentations table tbody tr td {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
.presentations table.outcomes-table.short th {
  padding: 5px;
  color: #fff;
  background: #3271aa;
  border: 1px solid #efefef;
}
.presentations table.outcomes-table.short th.effect-head-cell {
  background-color: #95b3d7;
  color: #000;
}
.presentations table.outcomes-table.short th.diagnostic-effect.prevalence-1 {
  background-color: #b8cce4;
  color: #000;
}
.presentations table.outcomes-table.short td.diagnostic-effect {
  background-color: #dbe5f1;
}
.presentations table.outcomes-table.short td.disabled-cell,
.presentations table.outcomes-table.short td.not-editable-cell {
  background-color: #fff;
}
.presentations table.dx-inner-table thead th {
  background-color: #d2dfed;
}
.presentations table th.blue-cell,
.presentations table td.blue-cell {
  color: #fff;
  background: #3271aa;
  border: 1px solid #efefef;
}
.presentations table th.grey-cell,
.presentations table td.grey-cell,
.presentations table th.disabled-cell,
.presentations table td.disabled-cell {
  border-top: 1px solid #efefef;
  border-right: 1px solid #efefef;
  background: #e0e0e0;
}
.presentations table th.risk-difference-with-intervention,
.presentations table td.risk-difference-with-intervention,
.presentations table th.corresponding-risk,
.presentations table td.corresponding-risk {
  background: #ebebeb;
}
.presentations table.standard-table th {
  text-align: center;
}
.presentations table.standard-table tr,
.presentations table.standard-table td,
.presentations table.standard-table th {
  border: 1px solid #000;
}
.presentations table.standard-table td,
.presentations table.standard-table th {
  padding: 5px;
}
.presentations .panel-toggle > p {
  color: #666;
  font-weight: bold;
  cursor: pointer;
  font-size: 11pt;
  line-height: 16px;
}
.presentations .panel-toggle > p:hover {
  color: #333;
}
.presentations .panel-toggle > p span {
  float: left;
  display: inline-block;
  position: relative;
}
.presentations .panel-toggle > p span .icon {
  width: 0;
  height: 0;
}
.presentations .panel-toggle > p.expanded .icon {
  top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #5a9bad;
}
.presentations .panel-toggle > p.collapsed .icon {
  margin-left: 8px;
  margin-right: 8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #5a9bad;
}
.presentations .panel-toggle > p:after {
  clear: both;
  display: table;
  content: " ";
}
.presentations .panel-toggle div.expanded {
  padding: 0px 10px;
}
.presentations div.content {
  padding: 10px;
  border: 1px solid #afb1b3;
}
.presentations div.content p {
  margin: 5px 0px;
}
.presentations div.content.static {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
.presentations .recommendation-header {
  padding: 10px;
  background: #e0e0e0;
}
.presentations .recommendation-header .content.static div.content,
.presentations .recommendation-header .editor-container div.content {
  padding: 18px;
  background: #e0e0e0;
  min-height: 30px !important;
}
.presentations .recommendation-header .sign {
  position: relative;
  z-index: 2;
  display: block;
  width: 50px;
  height: 50px;
  float: left;
  margin-left: 1px;
  margin-top: 1px;
}
.presentations .recommendation-header .sign.strongly-recommended-for {
  background: url("../images/recom-strong.png") 50% 50%;
}
.presentations .recommendation-header .sign.strongly-recommended-against {
  background: url("../images/not-recom.png") 50% 50%;
}
.presentations .recommendation-header .sign.conditional-recommended-for {
  background: url("../images/recom-ok.png") 50% 50%;
}
.presentations .recommendation-header .sign.conditional-recommended-against {
  background: url("../images/recom-not-really-ok.png") 50% 50%;
}
.presentations .recommendation-header .sign.conditional-either {
  background: url("../images/recom-ok.png") 50% 50%;
}
.presentations .recommendation-header .text {
  margin-top: 15px;
  display: block;
  padding-left: 60px;
}
.presentations label {
  display: inline-block;
  width: 140px;
  font-weight: bold;
  margin-right: 10px;
}
.presentations .label-with-input {
  margin: 5px 0px;
}
.presentations .label-with-input input {
  font-size: 10pt;
  padding: 2px 5px;
  border: 1px solid #afb1b3;
  border-radius: 3px;
  line-height: 18px;
}
.presentations .label-with-contenteditable {
  margin-bottom: 5px;
}
.presentations .label-with-contenteditable label {
  float: left;
}
.presentations .label-with-contenteditable .content {
  float: left;
  max-width: 400px;
  width: 400px;
}
.presentations .label-with-contenteditable .content.static {
  margin-top: 0px;
  padding-top: 0px;
}
.presentations .label-with-contenteditable:after {
  clear: both;
  display: table;
  content: " ";
}
.presentations .decision .radio-buttons {
  margin-bottom: 10px;
  display: table;
  width: 100%;
}
.presentations .decision .radio-buttons ul,
.presentations .decision .radio-buttons ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.presentations .decision .radio-buttons ul {
  display: table-row;
}
.presentations .decision .radio-buttons ul li {
  padding: 10px 0px;
  display: table-cell;
  background: #f1f2f2;
  text-align: center;
  border-right: 5px solid #fff;
}
.presentations .decision .radio-buttons ul li label {
  margin: 0px 5px;
  font-weight: normal;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}
.presentations .decision .radio-buttons ul li label span {
  display: block;
}
.presentations .decision .radio-buttons ul li.additional-options-separator {
  padding: 0;
  margin: 0;
  width: 2px;
}
.presentations .decision.detailed .radio-buttons {
  margin-bottom: 0px;
}
.presentations .decision.detailed .radio-buttons ul li {
  background: #e4f0f4;
}
.btn {
  cursor: pointer;
  display: inline-block;
  line-height: 36px;
  padding: 0;
  border-width: 0px !important;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  color: #5b666e;
  background-color: #cecece;
}
.btn:hover {
  color: #5b666e;
  background-color: #ccc;
}
.btn.btn-block {
  display: block !important;
  width: 100% !important;
}
.btn.btn-block.btn-success {
  color: #17a01b !important;
  background-color: #c9dac2 !important;
}
.btn.btn-block.btn-success:hover {
  background-color: #c7d8c0 !important;
}
.main-tabs .tabpanel {
  padding: 0px 10px;
}
.main-tabs .tabpanel .tab-headers {
  width: 100%;
}
.main-tabs .tabpanel .tab-headers .nav {
  margin: 0;
  padding: 10px 10px 0px;
  border-bottom: 1px solid #afb1b3;
}
.main-tabs .tabpanel .tab-headers .nav.nav-tabs {
  list-style: none;
}
.main-tabs .tabpanel .tab-headers .nav.nav-tabs li {
  width: 16%;
  font-size: 11pt;
  text-align: center;
  color: #808285;
  float: left;
  cursor: pointer;
  margin-bottom: -1px;
  margin-right: 5px;
  text-decoration: none;
  background: linear-gradient(#e4e5e6 50%, #f3f3f3);
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #afb1b3;
}
.main-tabs .tabpanel .tab-headers .nav.nav-tabs li a,
.main-tabs .tabpanel .tab-headers .nav.nav-tabs li a:hover,
.main-tabs .tabpanel .tab-headers .nav.nav-tabs li a:active,
.main-tabs .tabpanel .tab-headers .nav.nav-tabs li a:visited {
  display: block;
  width: 100%;
  text-decoration: none;
  color: #808285;
}
.main-tabs .tabpanel .tab-headers .nav.nav-tabs li.active {
  background: #fff;
  border-bottom: 1px solid #fff;
}
.main-tabs .tabpanel .tab-headers .nav:after {
  clear: both;
  display: table;
  content: " ";
}
.main-tabs .bottom-tabs .tab-headers,
.main-tabs .top-tabs .tab-headers {
  display: block;
}
.main-tabs .bottom-tabs .tab-headers .nav,
.main-tabs .top-tabs .tab-headers .nav {
  display: block;
  border-bottom: none;
}
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li {
  width: 11%;
  font-size: 10pt;
  display: inline-block;
  padding: 6px 0px;
  margin-bottom: -1px;
  margin-right: 5px;
  border: 1px solid #afb1b3;
  background: #fff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li a:hover,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li a:hover,
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li a:active,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li a:active,
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li a:visited,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li a:visited {
  color: #c3c3c3;
}
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li.active,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li.active {
  background: #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li.active a:hover,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li.active a:hover,
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li.active a:active,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li.active a:active,
.main-tabs .bottom-tabs .tab-headers .nav.nav-tabs li.active a:visited,
.main-tabs .top-tabs .tab-headers .nav.nav-tabs li.active a:visited {
  color: #333;
}
.main-tabs .bottom-tabs .tab-content,
.main-tabs .top-tabs .tab-content {
  background: #f6f6f6;
  border-top: none;
  border-radius: 3px;
  border: 1px solid #afb1b3;
}
.main-tabs .bottom-tabs .tab-content div.content,
.main-tabs .top-tabs .tab-content div.content,
.main-tabs .bottom-tabs .tab-content div.inner-tab-content,
.main-tabs .top-tabs .tab-content div.inner-tab-content {
  background: #fff;
  padding: 10px;
}
.main-tabs .tab-content {
  clear: both;
  padding: 10px;
}
.main-tabs .tab-content h4 {
  margin: 10px 0px;
}
