.row,
.form-group {
  margin-right: -5px;
  margin-left: -5px;
}
.col-12 {
  width: 100%;
}
.col-11 {
  width: 91.66666667%;
}
.col-10 {
  width: 83.33333333%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.66666667%;
}
.col-7 {
  width: 58.33333333%;
}
.col-6 {
  width: 50%;
}
.col-5-5 {
  width: 45.83333335%;
}
.col-5 {
  width: 41.66666667%;
}
.col-4 {
  width: 33.33333333%;
}
.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.66666667%;
}
.col-1 {
  width: 8.33333333%;
}
.col-offset-11 {
  margin-left: 91.66666667%;
}
.col-offset-10 {
  margin-left: 83.33333333%;
}
.col-offset-9 {
  margin-left: 75%;
}
.col-offset-8 {
  margin-left: 66.66666667%;
}
.col-offset-7 {
  margin-left: 58.33333333%;
}
.col-offset-6 {
  margin-left: 50%;
}
.col-offset-5-5 {
  margin-left: 45.83333335%;
}
.col-offset-5 {
  margin-left: 41.66666667%;
}
.col-offset-4 {
  margin-left: 33.33333333%;
}
.col-offset-3 {
  margin-left: 25%;
}
.col-offset-2 {
  margin-left: 16.66666667%;
}
.col-offset-1 {
  margin-left: 8.33333333%;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-5-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
  float: left;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.w-400 {
  width: 400px;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.link {
  text-decoration: underline;
}
.link,
.link:hover {
  color: #2d3236;
}
.link:hover {
  text-decoration: none;
}
.no-border {
  border-width: 0 !important;
}
.btn {
  display: inline-block;
  line-height: 36px;
  padding: 0 15px;
  border-width: 0;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  color: #5b666e;
  background-color: #cecece;
}
.btn:hover {
  color: #5b666e;
  background-color: #ccc;
}
.btn.loading {
  background: url("../images/loader.gif") 10px center no-repeat;
}
.btn-success {
  color: #17a01b !important;
  background-color: #c9dac2 !important;
}
.btn-success:hover {
  background-color: #c7d8c0 !important;
}
.btn-info {
  color: #62778c !important;
  background-color: #c0d3ea !important;
}
.btn-info:hover {
  background-color: #c6d9f0 !important;
}
.btn-danger {
  color: #c82a10 !important;
  background-color: #e9c6c0 !important;
}
.btn-danger:hover {
  background-color: #f0cdc7 !important;
}
.btn-primary {
  color: #30343f !important;
  background-color: #ddd !important;
  border: 1px solid #c1c1c1;
}
.btn-primary:hover {
  background-color: #d8d8d8 !important;
}
.btn-block {
  display: block !important;
  width: 100% !important;
}
.form .form-group {
  margin-bottom: 10px;
}
.form .form-group label {
  display: block;
  max-width: 100%;
  font-weight: 700;
  line-height: 20px;
}
.form-horizontal .form-group label {
  line-height: 36px;
}
.form-control {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0 10px !important;
  line-height: 34px;
  color: #555;
  background: #fff;
  border: 1px solid #ccc;
}
.row:before,
.row:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  clear: both;
  display: table;
  content: " ";
}
