.question-with-outcomes-container {
  background: #fff;
  width: 100%;
}
.question-with-outcomes-container .outcomes-table-container {
  overflow-x: hidden;
}
.question-with-outcomes-container > .outcomes-container {
  min-width: 1162px;
}
.question-with-outcomes-container > .footnotes-container {
  min-width: 1182px;
}
.question-with-outcomes-container .questions-view {
  margin-bottom: 10px;
}
.question-with-outcomes-container .questions-view .standard-list {
  background: #eaeaea;
}
.question-with-outcomes-container .questions-view .standard-list li.question .caption {
  float: left;
}
.question-with-outcomes-container .questions-view .standard-list li.question .quesiton-attr-footnotes {
  float: left;
  padding-right: 60px;
}
.question-with-outcomes-container .diagnostic-meta {
  margin: 0px 5px 15px 0;
}
.question-with-outcomes-container .diagnostic-meta div.pooled-values-container,
.question-with-outcomes-container .diagnostic-meta div.prevalences-container {
  display: inline-block;
  margin-right: 20px;
}
