.favour-double-left.active {
  color: #ca0020;
  font-weight: bold;
}
.favour-left.active {
  color: #f4a582;
  font-weight: bold;
}
.favour-left-right.active {
  color: #333;
  font-weight: bold;
}
.favour-right.active {
  color: #92c5de;
  font-weight: bold;
}
.favour-double-right.active {
  color: #0571b0;
  font-weight: bold;
}
.summary-of-judgements {
  height: 100%;
}
.summary-of-judgements table,
.summary-of-judgements tbody,
.summary-of-judgements tr,
.summary-of-judgements td {
  height: 100%;
}
.summary-of-judgements thead th {
  padding: 10px;
  text-transform: uppercase;
}
.summary-of-judgements thead th.favours-header {
  width: 104px;
}
.summary-of-judgements thead th.importance-header {
  width: 95px;
}
.summary-of-judgements td {
  vertical-align: middle;
  text-align: center;
  line-height: 24pt;
}
.summary-of-judgements td.low {
  background-color: #fcfcfc;
}
.summary-of-judgements td.moderate {
  background-color: #f0f0f0;
}
.summary-of-judgements td.high {
  background-color: #d9d9d9;
}
.summary-of-judgements .section-name {
  background-color: #dedede;
  width: 170px;
  text-transform: uppercase;
}
.summary-of-judgements .options-container {
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;
}
.summary-of-judgements .options-container .options-container-table {
  border-collapse: collapse;
  border-spacing: 0;
  min-height: 55px;
  border: 0;
  margin: 0;
  width: 100%;
}
.summary-of-judgements .options-container .options-container-table .options td:first-child {
  border-left: 0;
}
.summary-of-judgements .options-container .options-container-table .options td:nth-last-child(-n+2) {
  border-left: 2px solid #bfbfbf;
  width: 80px;
}
.summary-of-judgements .options-container .options-container-table .options td:nth-last-child(-n+1) {
  border-left: 1px solid #bfbfbf;
  border-right: 0;
}
.summary-of-judgements .options-container .options-container-table .option {
  vertical-align: middle;
  min-width: 104px;
  max-width: 130px;
  width: 130px;
  height: 55px;
  border-top: 0;
  border-bottom: 0;
}
.summary-of-judgements .options-container .options-container-table .option.checked {
  background-color: #a6dba0;
  font-weight: bold;
}
.summary-of-judgements .favours-container {
  font-size: 24pt;
  color: #bfbfbf;
}
.summary-of-judgements .importance {
  text-transform: uppercase;
}
.summary-of-judgements .importance.low {
  background-color: #fcfcfc;
}
.summary-of-judgements .importance.moderate {
  background-color: #f0f0f0;
}
.summary-of-judgements .importance.high {
  background-color: #d9d9d9;
}
.soj-editor {
  background-color: #f6f6f6;
  padding: 10px;
}
.soj-editor .favours-container {
  font-size: 14px;
  color: #2d3236;
}
.soj-editor .favours-container label {
  display: block;
  border: 1px solid #bfbfbf;
  padding: 5px;
  position: relative;
}
.soj-editor .favours-container label input {
  position: relative;
  bottom: 0px;
}
.soj-editor .favours-container .favour {
  font-family: gdt;
  font-size: 32px;
  line-height: 54px;
  text-align: center;
}
.soj-editor .favours-container .favour-empty {
  line-height: 73px;
}
.soj-editor .buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.soj-editor .buttons .apply {
  background-color: #c9dac2;
  color: #17a01b;
}
.soj-editor .buttons .btn {
  flex: 1;
  margin-top: 10px;
  line-height: 28px;
}
.soj-editor .buttons .btn:first-child {
  margin-right: 10px;
}
.soj-editor .main-question {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.soj-editor .main-question .label {
  text-transform: uppercase;
}
.soj-editor .main-question .question {
  padding-left: 10px;
  font-weight: bold;
}
.soj-editor .evaluation-question {
  display: flex;
  flex-direction: row;
  font-size: inherit;
}
.soj-editor select {
  margin-left: 20px;
  border: 1px solid #bfbfbf;
  height: 32px;
  -webkit-appearance: menulist;
  -webkit-rtl-ordering: logical;
}
.soj-editor .favours-selection {
  display: flex;
  flex-direction: row;
}
.soj-editor .favours-selection > div {
  padding: 10px;
  border: 1px solid #bfbfbf;
}
.soj-editor .favours-container {
  text-align: center;
}
.soj-editor .favours-container .description {
  height: 80%;
}
.soj-editor .favours-container div {
  cursor: pointer;
}
.soj-editor .favours-container input {
  margin-top: 10px;
}
.soj-favours-editor-dialog {
  width: 770px;
  height: 275px;
}
.soj-importance-editor-dialog {
  height: 140px;
}
.soj-clear-dialog {
  width: 550px;
  height: 90px;
}
