#open-project-dialog,
#import-dialog {
  margin-top: -20px;
}
#open-project-dialog fieldset,
#import-dialog fieldset {
  text-align: center;
}
#open-project-dialog #file,
#import-dialog #file {
  visibility: hidden;
  width: 0;
  height: 0;
}
#open-project-dialog .questions-to-import,
#import-dialog .questions-to-import {
  max-height: 120px;
  overflow-y: auto;
  background: #eaeaea;
}
#open-project-dialog .questions-to-import ul,
#import-dialog .questions-to-import ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#open-project-dialog .questions-to-import ul li,
#import-dialog .questions-to-import ul li {
  font-size: 16px;
  padding-bottom: 3px;
}
#open-project-dialog .questions-to-import ul li.selected,
#import-dialog .questions-to-import ul li.selected {
  background-color: #ddd;
}
#open-project-dialog .questions-to-import ul li input,
#import-dialog .questions-to-import ul li input {
  display: block;
  float: left;
}
#open-project-dialog .questions-to-import ul li input[type="checkbox"],
#import-dialog .questions-to-import ul li input[type="checkbox"] {
  margin-top: 5px;
  background-color: #fff;
}
#open-project-dialog .questions-to-import ul li input[type="checkbox"]:checked,
#import-dialog .questions-to-import ul li input[type="checkbox"]:checked {
  background-color: #ddd;
}
#open-project-dialog .questions-to-import ul li label,
#import-dialog .questions-to-import ul li label {
  display: block;
  margin-left: 20px;
}
#open-project-dialog .questions-to-import ul.outcomes-list,
#import-dialog .questions-to-import ul.outcomes-list {
  padding-left: 10px;
}
#open-project-dialog #rm5-url,
#import-dialog #rm5-url {
  width: 256px;
}
#open-project-dialog button.file,
#import-dialog button.file {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
