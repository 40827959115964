.document-publish h3 {
  margin: 0;
  padding: 0;
}
.mobile-contact-form form {
  padding: 0 0.5rem;
}
.mobile-contact-form form label,
.mobile-contact-form form input:not([type=submit]),
.mobile-contact-form form textarea {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.mobile-contact-form form textarea {
  height: 10rem;
}
.mobile-preview-view {
  width: 500px;
}
.mobile-preview-view.edit-mode {
  width: 800px;
}
.mobile-preview-view.edit-mode .original-text {
  display: block;
}
.mobile-preview-view .preview-frame {
  background: url("../images/iphone-frame.png") top left no-repeat;
  width: 290px;
  height: 600px;
}
.mobile-preview-view .original-text {
  display: none;
  width: 300px;
  height: 600px;
  position: absolute;
  top: 50px;
  left: 310px;
  bottom: 0;
}
.mobile-preview-view .original-text .fields {
  height: 450px;
  overflow-y: auto;
}
.mobile-preview-view .original-text .fields > div {
  width: 100%;
  min-height: 200px;
  border: 1px solid #bfbfbf;
  background: #fff;
}
.mobile-preview-view .preview-sidebar {
  position: absolute;
  bottom: 100px;
  right: 10px;
  width: 200px;
}
.mobile-preview-view #preview-content {
  border: none;
  background: #fff;
  position: relative;
  left: 23px;
  top: 87px;
  width: 238px;
  height: 421px;
}
.mobile-preview-view #qrcode {
  text-align: center;
  width: 290px;
}
.mobile-preview-view .info {
  margin: 1rem;
  text-align: center;
}
.mobile-preview-view .buttons-block {
  margin-top: 10px;
}
.mobile-preview-view .buttons-block button.close {
  float: right;
}
.mobile-preview-view .buttons-block button.send-preview {
  float: left;
}
.mobile-preview-view button.hidden {
  display: none !important;
}
.top-acceptence-notification,
.dbep-publication-notification {
  margin-top: 20px;
  text-align: center;
}
.disabled-holder {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
