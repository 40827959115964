.questions-view {
  min-width: 950px;
}
.questions-view li.expanded {
  font-size: 14px;
  height: 200px;
}
.questions-view li.expanded.diagnostic-index {
  height: 515px;
}
.questions-view li.expanded.diagnostic-comparator {
  height: 565px;
}
.questions-view li.expanded .rand-question {
  padding-right: 114px;
}
.questions-view li.expanded .rand-question input {
  width: 100%;
}
.questions-view li.expanded .separated {
  font-size: 14px;
  padding-left: 20px;
  padding-right: 24px;
}
.questions-view li.expanded .separated input {
  font-size: 14px;
}
.questions-view li.expanded .separated select {
  height: 30px;
  font-size: 14px;
}
.questions-view li.expanded .diagnostic {
  white-space: nowrap;
}
.questions-view li.expanded .diagnostic input {
  width: 17%;
}
.questions-view li.expanded .diagnostic input#healthProblemOrPopulation {
  width: 30%;
}
.questions-view li.expanded.diagnostic-comparator .diagnostic input,
.questions-view li.expanded.diagnostic-comparator .diagnostic input#healthProblemOrPopulation {
  width: 15%;
}
.questions-view li.expanded .details {
  padding-right: 64px;
}
.questions-view li.expanded .details .form-group {
  margin: 10px 0px;
}
.questions-view li.expanded .details .col-10 {
  width: 79%;
}
.questions-view li.expanded .details fieldset {
  line-height: 0px;
}
.questions-view li.expanded .details fieldset button {
  border: none;
}
.questions-view li.expanded .details fieldset > label {
  position: relative;
  display: inline-block;
  line-height: 16px;
  padding-left: 30px;
}
.questions-view li.expanded .details fieldset > label .question-mark-sign {
  border-width: 0;
  position: absolute;
  top: 1px;
  left: 10px;
  font-size: 12px;
}
.questions-view li.expanded .details fieldset > label .question-mark-sign:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.questions-view li.expanded .details fieldset > label .question-mark-sign * {
  margin-left: 0.5rem;
}
.questions-view li.expanded .details fieldset textarea {
  padding: 8px 10px !important;
  line-height: 16px;
  height: 50px;
}
.questions-view li.expanded .details fieldset textarea[disabled] {
  background-color: transparent;
}
.questions-view li.expanded .details fieldset .sof-title {
  position: relative;
  display: inline-block;
}
.questions-view li.expanded .details fieldset .sof-title textarea {
  padding-right: 110px;
}
.questions-view li.expanded .details fieldset .sof-title button.auto-manual-switch {
  height: auto;
  position: absolute;
  padding: 6px;
  line-height: 16px;
  right: 10px;
  top: 6px;
  z-index: 100;
  width: 160px;
}
.questions-view .last-update {
  line-height: 16px;
  font-size: 14px;
}
.questions-view .add-question-buttons li {
  width: 50%;
}
.questions-view .add-management-question span,
.questions-view .add-diagnostic-question span {
  background: url("../images/new-project.png") left center no-repeat;
  padding-left: 30px;
}
.questions-view .import-question span {
  background: url("../images/import-project.png") left center no-repeat;
  padding-left: 30px;
}
.questions-view .disabled-sof-title {
  position: relative;
}
.questions-view .disabled-sof-title div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.questions-view ul.standard-list li.questions-wo-group {
  position: static;
  height: auto;
  padding: 0;
  background: none;
  border: none;
}
.questions-view ul.standard-list li.questions-wo-group ul.grouped-questions {
  margin: 0;
}
.questions-view ul.standard-list li.question-group {
  height: auto;
  min-height: 50px;
  margin-bottom: 10px;
  background: #f1f1f1;
  border: 1px solid #bfbfbf;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view {
  padding-right: 10px;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view .caption {
  font-weight: bold;
  padding-left: 0.5em;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view button {
  border-right: none;
}
.questions-view ul.standard-list li.question-group .question-group-edit-view input {
  width: 90%;
}
.questions-view ul.standard-list ul.grouped-questions {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0 10px 10px 0;
  min-height: 10px;
}
.questions-view ul.standard-list li.active ul.grouped-questions.empty {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f1f1f1;
  border: 1px solid #bfbfbf;
  border-style: dashed;
}
.questions-view ul.standard-list li.active ul.grouped-questions.empty.about-to-drop {
  background: #d2dfed;
}
.questions-view .new-question-group-container {
  background: #fff;
  padding-top: 10px;
  position: relative;
  cursor: pointer;
  margin-top: -50px;
  z-index: 990;
}
.questions-view .new-question-group-container #new-question-group {
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #f1f1f1;
  border: 1px solid #bfbfbf;
  border-style: dashed;
}
.questions-view .new-question-group-container #new-question-group.about-to-drop {
  background: #d2dfed;
}
.questions-view .questions-container .question-footnotes-attr {
  display: none;
}
.questions-view .questions-container.drop-area-visible ul.standard-list {
  margin-bottom: 50px;
}
