table.diagnostic-prevalences {
  width: 250px;
}
table.diagnostic-prevalences tr {
  height: 45px;
}
table.diagnostic-prevalences td {
  background-color: #e9e9e9;
  line-height: 45px;
  padding: 2px 3px;
}
table.diagnostic-prevalences .value-cell {
  background-color: transparent;
  width: 50px;
  cursor: pointer;
  text-align: center;
}
table.diagnostic-prevalences .value-cell:hover {
  background-color: #ddeaf9;
}
