.ReactModal__Overlay {
  background-color: rgba(255,255,255,0.75);
  z-index: 10000;
  overflow: auto;
  box-sizing: border-box;
}
.ReactModal__Content {
  background-color: #eaeaea;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  position: relative;
  margin: 20px auto;
  border: 1px solid #bfbfbf;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.ReactModal__Content button.danger,
.ReactModal__Content input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
.ReactModal__Content button.danger:hover:not(:disabled),
.ReactModal__Content input.danger:hover:not(:disabled) {
  background-color: rgba(242,222,222,0.5);
}
.ReactModal__Content button.danger[disabled],
.ReactModal__Content input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
.ReactModal__Content button.finish,
.ReactModal__Content input.finish {
  background-color: #c9dac2;
  color: #036707;
}
.ReactModal__Content button.finish:hover:not(:disabled),
.ReactModal__Content input.finish:hover:not(:disabled) {
  background-color: rgba(201,218,194,0.5);
}
.ReactModal__Content button.finish[disabled],
.ReactModal__Content input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
.ReactModal__Content button.loading,
.ReactModal__Content input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
.ReactModal__Content .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 26px;
  color: #808080;
  cursor: pointer;
}
.ReactModal__Content .modal-close:before {
  content: '\00d7';
}
