* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  color: #2d3236;
  height: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: 'PTSans', sans-serif;
}
body {
  font-size: 16px;
  background: #567189;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  *zoom: 1;
}
body:before,
body:after {
  content: "";
  display: table;
}
body:after {
  clear: both;
}
.draggable {
  cursor: url("../images/openhand.cur"), auto;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.dragging {
  cursor: url("../images/closedhand.cur"), auto;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.salmon-bg {
  background-color: #f7bf9c;
}
.capitalize {
  text-transform: capitalize;
}
ol.example li.placeholder {
  position: relative;
}
ol.example li.placeholder:before {
  position: absolute;
}
h2 {
  font-size: 18px;
  font-size: 1.8rem;
}
h3 {
  font-size: 16px;
  font-size: 1.6rem;
}
input,
textarea,
div.content {
  border: 1px solid #bfbfbf;
  resize: none;
  outline: none;
}
input:focus,
textarea:focus,
div.content:focus {
  outline: #9bbde0 auto 5px;
}
*:hover {
  text-decoration: none !important;
}
select:focus,
textarea:focus,
input:focus,
button:focus {
  outline: none !important;
}
input:not([type]),
input[type=text],
input[type=email],
select {
  display: inline-block;
  padding: 0 8px;
  font-size: 16px;
  line-height: 30px;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.expanded select,
.separated select {
  border: 1px solid #bfbfbf;
  height: 32px;
  -webkit-appearance: menulist;
  -webkit-rtl-ordering: logical;
}
textarea,
div.content {
  padding: 5px;
}
input[disabled] {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
input[type=radio] {
  -webkit-appearance: none;
  border: 1px solid #bfbfbf;
  display: inline-block;
  margin: 0 0.25em 0 0;
  padding: 0;
  width: 13px;
  height: 13px;
  -webkit-border-radius: 0.25em;
  border-radius: 0.25em;
  vertical-align: text-top;
  background: #fff;
}
input[type=radio] {
  -webkit-border-radius: 2em;
  border-radius: 2em;
}
input[type=radio]:checked {
  background: url("../images/radiobutton.png") center center no-repeat;
}
input[type=checkbox] {
  -webkit-appearance: none;
  background: #fff;
  display: inline-block;
  width: 12px;
  height: 12px;
}
input[type=checkbox]:checked {
  background: url("../images/check.png") center center no-repeat;
}
.question-mark-sign {
  display: inline-block;
  cursor: pointer;
  height: 15px;
  width: 15px;
  line-height: 15px;
  border: 1px solid #000;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
}
.question-mark-sign:before {
  content: '?';
}
.standard-table {
  width: 100%;
}
.standard-table thead {
  background: #dedede;
}
.standard-table th {
  border: 1px solid #bfbfbf;
  padding: 0;
  font-weight: normal;
}
.standard-table th.with-info,
.standard-table td.with-info {
  position: relative;
  padding-right: 14px;
}
.standard-table th span.info-sign,
.standard-table td span.info-sign {
  display: block;
  width: 11px;
  height: 11px;
  content: url("../images/info.png");
  position: absolute;
  top: 2px;
  right: 2px;
}
.standard-table td {
  border: 1px solid #bfbfbf;
  padding: 0;
}
sup[data-footnote-id] span.underline {
  text-decoration: underline;
}
.error-message {
  margin: 10px 0;
  color: #9e0000;
}
.message-box {
  background-color: #d2dfed;
  color: #1a008d;
  border: 1px solid #bfbfbf;
  display: block;
  padding: 1rem;
  margin-bottom: 2rem;
}
::selection,
::-moz-selection {
  background: #9bbde0;
  color: #fff;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
  background-color: #cbcbcb;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.outer-container {
  position: absolute;
  top: 50px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  padding-top: 36px;
}
.outer-container.welcome-screen-container {
  top: 40px;
  padding-top: 10px;
}
#sidebar {
  width: 170px;
  float: left;
  height: 100%;
  margin: 0;
}
#page-container {
  position: relative;
  margin: 0;
  padding: 10px 10px;
  height: 100%;
  background: #fff;
  overflow: auto;
  border: 1px solid #bbb;
  border-top-width: 0;
}
#topbar {
  height: 36px;
  background: #ddd;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  border-bottom: 1px solid #bbb;
}
#topbar h2 {
  margin: 0;
  line-height: 36px;
}
#searchbox-container {
  width: 170px;
  float: left;
}
#toolbar-container a {
  display: block;
  float: left;
}
#toolbar-container a,
#toolbar-container a:link,
#toolbar-container a:visited,
#toolbar-container a:hover,
#toolbar-container a:active {
  color: inherit;
  text-decoration: none;
}
#toolbar-container .questions {
  line-height: 36px;
  padding: 0 10px;
  padding-left: 185px;
  overflow: hidden;
}
#toolbar-container .questions.opened .questions-menu {
  display: block;
  min-width: 650px;
}
#toolbar-container .questions.opened > a:before {
  font-family: 'gdt';
  content: '\e017';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#toolbar-container .questions.opened > a * {
  margin-left: 0.5rem;
}
#toolbar-container .questions > a {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
#toolbar-container .questions > a:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#toolbar-container .questions > a * {
  margin-left: 0.5rem;
}
#toolbar-container .questions > a:before {
  font-size: 20px;
  padding-right: 10px;
}
#toolbar-container .questions-menu {
  display: none;
  background-color: #eaeaea;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  background-color: #e9e9e9;
  position: absolute;
  top: 41px;
  margin-left: -10px;
  z-index: 999;
}
#toolbar-container .questions-menu button.danger,
#toolbar-container .questions-menu input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
#toolbar-container .questions-menu button.danger:hover:not(:disabled),
#toolbar-container .questions-menu input.danger:hover:not(:disabled) {
  background-color: rgba(242,222,222,0.5);
}
#toolbar-container .questions-menu button.danger[disabled],
#toolbar-container .questions-menu input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
#toolbar-container .questions-menu button.finish,
#toolbar-container .questions-menu input.finish {
  background-color: #c9dac2;
  color: #036707;
}
#toolbar-container .questions-menu button.finish:hover:not(:disabled),
#toolbar-container .questions-menu input.finish:hover:not(:disabled) {
  background-color: rgba(201,218,194,0.5);
}
#toolbar-container .questions-menu button.finish[disabled],
#toolbar-container .questions-menu input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
#toolbar-container .questions-menu button.loading,
#toolbar-container .questions-menu input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
#toolbar-container .questions-menu ul {
  display: block;
  border-style: none;
  overflow-y: auto;
  width: 100%;
  position: relative;
}
#toolbar-container .questions-menu ul li:first-child {
  border-top: none;
}
#toolbar-container .questions-menu ul .caption {
  display: block;
  white-space: normal;
  padding-right: 5px;
  font-size: 16px;
}
#toolbar-container .questions-menu ul .caption:hover div {
  text-decoration: underline;
}
#toolbar-container .questions-menu .mCSB_container {
  margin-right: inherit;
}
#toolbar-container .breadcrumbs {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: 920px;
  margin-left: 170px;
  font-size: 0;
}
#toolbar-container .breadcrumbs li {
  font-size: 16px;
  display: inline-block;
  position: relative;
}
#toolbar-container .breadcrumbs li span {
  display: inline-block;
}
#toolbar-container .breadcrumbs li a,
#toolbar-container .breadcrumbs li span {
  padding: 0 15px 0 40px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  overflow: hidden;
  background: #ddd;
}
#toolbar-container .breadcrumbs li a.selected,
#toolbar-container .breadcrumbs li span.selected {
  background: #d0d0d0;
  font-weight: bold;
}
#toolbar-container .breadcrumbs li a.selected:before,
#toolbar-container .breadcrumbs li span.selected:before {
  border-left: 20px solid #d0d0d0;
}
#toolbar-container .breadcrumbs li a:after,
#toolbar-container .breadcrumbs li span:after {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  z-index: 2;
  left: 100%;
  border: 18px solid transparent;
  border-left: 20px solid #000;
}
#toolbar-container .breadcrumbs li a:before,
#toolbar-container .breadcrumbs li span:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 100%;
  z-index: 3;
  width: 0;
  height: 0;
  border: 18px solid transparent;
  border-left: 20px solid #ddd;
}
#toolbar-container .breadcrumbs li a.disabled,
#toolbar-container .breadcrumbs li span.disabled {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  color: rgba(45,50,54,0.3);
}
#toolbar-container .breadcrumbs li:first-child span {
  padding-left: 20px;
}
#toolbar-container menu[type=toolbar] {
  padding: 0;
  margin: 0;
  list-style: none;
  float: right;
}
#toolbar-container menu[type=toolbar] a,
#toolbar-container menu[type=toolbar] button {
  width: 20px;
  height: 20px;
  margin: 7px 10px;
  float: left;
}
#toolbar-container menu[type=toolbar] span.toolbar-separator {
  display: inline-block;
  height: 36px;
  border-left: 1px solid #bfbfbf;
  float: left;
  margin: 0 10px;
}
#toolbar-container menu[type=toolbar].project {
  width: 30px;
}
#toolbar-container span.caption {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 36px;
  padding-left: 15px;
  font-weight: bold;
}
button {
  border: none;
  background: none;
  padding: 0;
}
button,
button:link,
button:visited,
button:hover,
button:active {
  color: inherit;
  text-decoration: none;
}
button.add.input-button:before {
  font-family: 'gdt';
  content: '\e025';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.add.input-button * {
  margin-left: 0.5rem;
}
button.activate:before {
  font-family: 'gdt';
  content: '\e005';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.activate * {
  margin-left: 0.5rem;
}
button.archive:before {
  font-family: 'gdt';
  content: '\e010';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.archive * {
  margin-left: 0.5rem;
}
button.arrow-right:before {
  font-family: 'gdt';
  content: '\e019';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.arrow-right * {
  margin-left: 0.5rem;
}
button.arrow-down:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.arrow-down * {
  margin-left: 0.5rem;
}
button.edit:before {
  font-family: 'gdt';
  content: '\e012';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.edit * {
  margin-left: 0.5rem;
}
button.delete:before {
  font-family: 'gdt';
  content: '\e022';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.delete * {
  margin-left: 0.5rem;
}
button.save:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.save * {
  margin-left: 0.5rem;
}
button.save-proceed:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.save-proceed * {
  margin-left: 0.5rem;
}
button.share:before {
  font-family: 'gdt';
  content: '\e020';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.share * {
  margin-left: 0.5rem;
}
button.footnotes:before {
  font-family: 'gdt';
  content: '\e015';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.footnotes * {
  margin-left: 0.5rem;
}
button.context-help:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.context-help * {
  margin-left: 0.5rem;
}
button.help:before {
  font-family: 'gdt';
  content: '\e009';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.help * {
  margin-left: 0.5rem;
}
button.sort:before {
  font-family: 'gdt';
  content: '\e027';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.sort * {
  margin-left: 0.5rem;
}
button.duplicate:before {
  font-family: 'gdt';
  content: '\e024';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.duplicate * {
  margin-left: 0.5rem;
}
button.remove:before {
  font-family: 'gdt';
  content: '\e026';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.remove * {
  margin-left: 0.5rem;
}
button.table-mode:before {
  font-family: 'gdt';
  content: '\e023';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
button.table-mode * {
  margin-left: 0.5rem;
}
button:hover:not(:disabled) {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
button.large {
  font-size: 17px;
  font-size: 1.7rem;
  padding: 1rem;
}
.label-disabled,
.label-disabled-disconnected {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
.label-disabled-disconnected {
  padding-right: 25px;
  background: url("../images/no-connection.png") no-repeat center right;
}
.editor-container {
  position: relative;
}
.editor-container .content {
  background: #fff;
  border: 1px solid #bfbfbf;
  text-align: left;
  padding: 5px;
  min-height: 150px;
}
.editor-container .static-content {
  cursor: pointer;
}
.editor-container .buttons-react,
.editor-container .buttons,
.editor-container .col-6 {
  padding: 0 !important;
  margin: 0 !important;
}
.editor-container div.buttons,
.editor-container div.editable-content {
  display: none;
}
.editor-container div.top-buttons {
  display: block;
  position: absolute;
  top: 5px;
  right: 10px;
}
.editor-container.edit-content div.static-content {
  display: none;
}
.editor-container.edit-content div.editable-content {
  display: block;
}
.editor-container.edit-content div.buttons {
  display: inline-block;
  width: 100%;
}
menu[type="toolbar"] .outcome-view:before {
  font-family: 'gdt';
  content: '\e004';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .outcome-view * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .export:before {
  font-family: 'gdt';
  content: '\e009';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .export * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .preview:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .preview * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .save:before {
  font-family: 'gdt';
  content: '\e021';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .save * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .synchronize:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .synchronize * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .print:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .print * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .send-mail:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .send-mail * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .open-file {
  background-position: center center;
}
menu[type="toolbar"] .open-file:before {
  font-family: 'gdt';
  content: '\e000';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
menu[type="toolbar"] .open-file * {
  margin-left: 0.5rem;
}
menu[type="toolbar"] .footnotes:before,
menu[type="toolbar"] .context-help:before {
  display: inline-block;
  padding-bottom: 2px;
  padding-right: 3px;
}
menu[type="toolbar"] .context-help:before {
  font-size: 12px;
}
menu[type="toolbar"] button {
  width: 100%;
  height: 100%;
  line-height: 20px;
}
button:hover:not(:disabled) {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
button:disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.empty .standard-list {
  border-width: 0 !important;
}
.standard-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: table;
  width: 100%;
  overflow: visible;
  font-size: 16px;
}
.standard-list li:first-child {
  border-top: 1px solid #bfbfbf;
}
.standard-list li:first-child,
.standard-list li:last-child {
  height: 41px;
}
.standard-list li:only-child {
  height: 42px;
}
.standard-list li:last-child {
  border-bottom: 1px solid #bfbfbf;
}
.standard-list li {
  border-left: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  height: 40px;
  line-height: 40px;
}
.standard-list li {
  display: block;
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -o-transition: height 0.5s;
  -ms-transition: height 0.5s;
  transition: height 0.5s;
  position: relative;
  padding-left: 10px;
  background: #e9e9e9;
  overflow: hidden;
}
.standard-list li.expanded .separated {
  min-height: 40px;
  margin-left: 10px;
  padding-left: 10px;
  margin-right: 40px;
}
.standard-list li.expanded .draft {
  margin-right: 0;
}
.standard-list li.expanded .details {
  overflow: hidden;
}
.standard-list li:nth-child(odd) {
  background-color: #ddd;
}
.standard-list li:hover:not(.expanded),
.standard-list li.current {
  background-color: #fff;
}
.standard-list li a,
.standard-list li button {
  height: 40px;
}
.standard-list li a,
.standard-list li button,
.standard-list li a:link,
.standard-list li button:link,
.standard-list li a:visited,
.standard-list li button:visited,
.standard-list li a:hover,
.standard-list li button:hover,
.standard-list li a:active,
.standard-list li button:active {
  color: inherit;
  text-decoration: none;
}
.standard-list li button {
  display: block;
  float: right;
  width: 40px;
  background-position: center center;
  border: none;
  border-left: 1px solid #bfbfbf;
}
.standard-list li .caption {
  height: 40px;
  padding-right: 60px;
  line-height: 14px;
}
.standard-list li .caption:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.standard-list li .caption div {
  display: inline-block;
  vertical-align: middle;
}
.standard-list li .caption div {
  width: 99%;
}
.standard-list li .side-buttons {
  position: absolute;
  width: 40px;
  top: 0;
  right: 0;
  bottom: 0;
  border-left: 1px solid #bfbfbf;
}
ol.standard-list {
  counter-reset: li;
}
ol.standard-list li {
  padding-left: 50px;
}
ol.standard-list li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  border-right: 1px solid #bfbfbf;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
}
ol.standard-list .form-horizontal {
  padding-right: 50px;
}
ol.standard-list .form-horizontal label {
  line-height: 40px;
}
.ui-sortable li {
  cursor: url("../images/openhand.cur"), auto;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}
.sortable-placeholder {
  border-top: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  background: #cdcdcd !important;
}
.ui-sortable-helper {
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  margin: 0 auto;
}
.ui-sortable-helper span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
.ui-sortable-helper ul {
  position: relative;
}
.ui-sortable-helper ul li {
  background: #f6f6f6;
}
.ui-sortable-helper,
.ui-sortable-helper a {
  cursor: url("../images/closedhand.cur"), auto;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.buttons-outer {
  padding-top: 10px;
}
menu.buttons {
  padding: 0;
  margin: 0;
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 40px;
}
menu.buttons li {
  display: table-cell;
  text-align: center;
  background: #cecece;
  height: 40px;
  line-height: 40px;
}
menu.buttons li:hover {
  background: #d3d3d3;
}
menu.buttons li a {
  display: block;
}
menu.buttons li a,
menu.buttons li a:link,
menu.buttons li a:visited,
menu.buttons li a:hover,
menu.buttons li a:active {
  color: inherit;
  text-decoration: none;
}
menu.buttons li a span {
  display: inline-block;
}
menu.buttons.questions {
  margin-top: 15px;
}
.scrolled.mCustomScrollbar + menu.buttons {
  padding-right: 49px;
}
ol + menu.buttons,
menu.buttons.after-ol {
  padding-left: 0;
  padding-right: 0;
}
ol + menu.buttons button,
menu.buttons.after-ol button {
  margin: -10px !important;
}
.scrolled {
  overflow: auto;
  position: relative;
}
.mCSB_container {
  margin-right: 0px;
}
.sticky-header + .scrolled tr:first-child td,
.sticky-header + .scrolled tr:first-child th {
  border-top: none;
}
.native-scroll {
  position: static;
}
.box-loading {
  height: 200px;
  background: url("../images/loader-big.gif") center center no-repeat;
}
#content {
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loading-screen {
  width: 415px;
  margin: 87px auto;
}
.loading-screen .messages {
  padding: 47px 14% 7%;
  background: #eaeaea;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  position: relative;
}
.loading-screen .messages div {
  font-size: 16px;
  text-align: center;
}
.loading-screen .messages div.warning {
  color: #f00;
  margin-bottom: 1rem;
  display: none;
}
.loading-screen .messages div.loading-text {
  font-size: 18px;
  font-size: 1.8rem;
}
.loading-screen .messages .logo {
  background: url("../images/logo94.png") top left no-repeat;
  text-indent: -9999px;
  width: 94px;
  height: 94px;
  display: block;
  position: absolute;
  margin: 0;
  top: -47px;
  left: 160.5px;
}
html.non-webkit .loading-screen .warning.non-webkit {
  display: block;
}
html.lt-ie10 .loading-screen .warning.non-webkit {
  display: none;
}
html.lt-ie10 .loading-screen .warning.old-ie {
  display: block;
}
html.lt-ie10 .loading-screen .box-loading {
  display: none;
}
.input-with-button {
  position: relative;
}
.input-with-button button.input-button {
  position: absolute;
  height: 32px;
  width: 32px;
  display: inline;
  border: none;
  float: none;
  top: -7px;
  right: 3px;
  border: 1px solid #bfbfbf;
  background-color: #ebebe4;
}
.input-with-button button.input-button:before {
  vertical-align: initial;
  line-height: 22px;
}
.text-with-limit {
  position: relative;
}
.text-with-limit input,
.text-with-limit textarea {
  padding-right: 45px;
}
.text-with-limit .inner-text {
  position: absolute;
  line-height: initial;
  top: 0;
  right: 5px;
  color: #808080;
}
.for-print {
  display: none;
}
.medium-editor-toolbar {
  z-index: 11000;
}
@font-face {
  font-family: 'gdt';
  src: url("../fonts/gdt.eot");
  src: url("../fonts/gdt.eot?#iefix") format('embedded-opentype'), url("../fonts/gdt.woff") format('woff'), url("../fonts/gdt.ttf") format('truetype'), url("../fonts/gdt.svg#gdt") format('svg');
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: 'gdt';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.quality-sign {
  font-family: 'GRADE-quality', Cambria, Helvetica, Arial;
  font-weight: normal;
  font-size: 14px;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
@media print {
  #header-container,
  #topbar,
  #sidebar-container {
    display: none;
  }
  .outer-container {
    position: static;
    padding: 0;
  }
  table {
    font-family: "Arial Narrow", Arial, sans-serif;
  }
  .for-print {
    display: inherit;
  }
  .no-print {
    display: none;
  }
  .dont-split {
    page-break-inside: avoid;
  }
  .mCSB_container {
    margin-right: inherit;
  }
  .mCustomScrollBox .mCSB_scrollTools {
    padding: 6px 0;
    width: inherit;
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_draggerRail {
    width: 6px;
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: #cecece;
    -webkit-box-shadow: 1px 1px 1px 1px rgba(78,78,78,0.5) inset;
    box-shadow: 1px 1px 1px 1px rgba(78,78,78,0.5) inset;
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: #9bbde0;
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
  }
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
  .mCustomScrollBox .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
}
