.outcomes-container {
  overflow-y: auto;
  font-size: 14px;
}
.outcomes-container .footnote-active {
  background-color: #f4e39e;
}
.outcomes-container input[type="text"] {
  height: 30px;
  font-size: 14px;
  line-height: 16px;
}
.outcomes-container table td {
  cursor: pointer;
  position: relative;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
}
.outcomes-container table td:hover {
  background-color: #ddeaf9;
}
.outcomes-container table td:hover .note {
  display: block;
  background: url("../images/note-large.png") top left no-repeat;
  text-indent: -9999px;
}
.outcomes-container table td button.note {
  display: none;
  position: absolute;
  height: 17px;
  width: 16px;
  background: url("../images/note.png") top left no-repeat;
  text-indent: -9999px;
  top: 0;
  right: 0;
  background-position: center center;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.outcomes-container table td button.note,
.outcomes-container table td button.note:link,
.outcomes-container table td button.note:visited,
.outcomes-container table td button.note:hover,
.outcomes-container table td button.note:active {
  color: inherit;
  text-decoration: none;
}
.outcomes-container table td button.note.notes-not-empty {
  display: block;
  background: url("../images/note-large.png") top left no-repeat;
  text-indent: -9999px;
}
.outcomes-container table td button.note {
  display: none;
  position: absolute;
  height: 17px;
  width: 16px;
  background: url("../images/note.png") top left no-repeat;
  text-indent: -9999px;
  top: 0;
  right: 0;
  background-position: center center;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.outcomes-container table td button.note,
.outcomes-container table td button.note:link,
.outcomes-container table td button.note:visited,
.outcomes-container table td button.note:hover,
.outcomes-container table td button.note:active {
  color: inherit;
  text-decoration: none;
}
.outcomes-container table td button.note.notes-not-empty {
  display: block;
}
.outcomes-container table td hr {
  padding: 0;
  margin: 0;
}
.outcomes-container table .outcome-row.editing td {
  background-color: #d2dfed;
}
.outcomes-container table .outcome-row.editing button.save {
  display: inline-block;
}
.outcomes-container table .outcome-row.editing button.edit {
  display: none;
}
.outcomes-container table .outcome-row:not(.editing) button.save {
  display: none;
}
.outcomes-container table .outcome-row:not(.editing) button.edit {
  display: inline-block;
}
.outcomes-container table .outcome-row td {
  word-break: break-word;
}
.outcomes-container table .edit-sof-outcome {
  width: 25px;
  vertical-align: middle;
}
.outcomes-container table .edit-sof-outcome button.edit {
  border-left: none;
}
.outcomes-container table .edit-sof-outcome button.save {
  display: none;
}
.outcomes-container table td.control-label {
  font-weight: bold;
  padding: 5px 0px;
  height: auto;
  background-color: #e0e0e0;
}
.outcomes-container table td.edit-sof-outcome {
  background-color: #d2dfed !important;
}
.outcomes-container table td.warning {
  background-color: #f7bf9c;
}
.outcomes-container table .quality {
  text-align: center;
}
.outcomes-container table .inner-cell {
  padding: 2px 3px;
}
.outcomes-container table .cell-content {
  min-height: 30px;
  display: inline-block;
  padding: 2px 3px;
}
.outcomes-container table .inner-cell .cell-content {
  padding: 0;
}
.outcomes-container table .active {
  background-color: #d2dfed !important;
}
.outcomes-container table .not-editable-cell,
.outcomes-container table .disabled-cell {
  cursor: default;
  background-color: #e9e9e9;
}
.outcomes-container table .outcome-label {
  line-height: 30px;
  text-align: left;
  background: #d2dfed;
  height: 30px;
}
.outcomes-container table .outcome-label .ellipsis-wrapper {
  display: inline-block;
}
.outcomes-container table .outcome-label .outcome-name {
  margin: 0px 5px;
}
.outcomes-container table .outcome-cell {
  background: transparent;
}
.outcomes-container table button.sort,
.outcomes-container table button.save,
.outcomes-container table button.edit,
.outcomes-container table button.delete {
  width: 30px;
  height: 30px;
  float: right;
  font-size: 16px;
  background-position: center center;
}
.outcomes-container menu.buttons.disabled {
  cursor: default;
  background-color: rgba(206,206,206,0.2);
  color: rgba(45,50,54,0.4);
}
.outcomes-container .absolute-difference td {
  height: 20px;
  line-height: 20px;
}
.outcomes-container .outcome-label-row.edit-label-state {
  border-right: 0 !important;
}
.outcomes-container .outcome-label-row .outcome-label div.name-block {
  padding-right: 30px;
}
.outcomes-container .outcome-edit-block {
  display: none;
}
.outcomes-container tr .table-mode {
  width: 30px;
}
.outcomes-container tr.outcome-one-row > th {
  word-break: break-word;
}
.outcomes-container tr.outcome-one-row .no-of-studies {
  width: 5%;
}
.outcomes-container tr.outcome-one-row .design-studies {
  width: 8%;
}
.outcomes-container tr.outcome-one-row .risk-of-bias {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .inconsistency {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .indirectness {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .imprecision {
  width: 7%;
}
.outcomes-container tr.outcome-one-row .no-of-patients {
  width: 16%;
}
.outcomes-container tr.outcome-one-row .relative-effect,
.outcomes-container tr.outcome-one-row .intervention,
.outcomes-container tr.outcome-one-row .control {
  width: 8%;
}
.outcomes-container tr.outcome-one-row .other-considerations {
  width: 11%;
}
.outcomes-container tr.outcome-one-row .quality {
  width: 6%;
}
.outcomes-container tr.outcome-one-row .importance {
  width: 10%;
}
.outcomes-container tr.outcome-one-row .importance div.table-menu {
  display: inline-block;
  float: right;
  border-left: 1px solid #bfbfbf;
  height: 100%;
}
.outcomes-container tr.outcome-one-row .importance div.table-menu button {
  margin-top: 95%;
}
.outcomes-container tr.outcome-one-row .importance span.table-menu {
  display: inline-block;
  padding-top: 25%;
  height: 100%;
}
.outcomes-container tr.outcome-sof-row .outcome-cell {
  width: auto;
}
.outcomes-container tr.outcome-sof-row .control {
  width: 12%;
}
.outcomes-container tr.outcome-sof-row .with-intervention,
.outcomes-container tr.outcome-sof-row .without-intervention,
.outcomes-container tr.outcome-sof-row .difference,
.outcomes-container tr.outcome-sof-row .risk-difference-with-intervention,
.outcomes-container tr.outcome-sof-row .risk-with-control,
.outcomes-container tr.outcome-sof-row .corresponding-risk {
  width: 12%;
}
.outcomes-container tr.outcome-sof-row .relative-effect {
  width: 12%;
}
.outcomes-container tr.outcome-sof-row .no-of-participants {
  width: 12%;
}
.outcomes-container tr.outcome-sof-row .quality {
  width: 12%;
}
.outcomes-container tr.outcome-sof-row .what_happens,
.outcomes-container tr.outcome-sof-row .comments {
  width: 26%;
}
.outcomes-container tr.outcome-sof-row .impact {
  width: 36%;
}
.outcomes-container tr.outcome-accp-row .participants {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .risk-of-bias {
  width: 6%;
}
.outcomes-container tr.outcome-accp-row .inconsistency {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .indirectness {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .imprecision {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .other-considerations {
  width: 10%;
}
.outcomes-container tr.outcome-accp-row .quality {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .case-control {
  width: 16%;
}
.outcomes-container tr.outcome-accp-row .event-rates-with-control {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .event-rates-with-intervention {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .relative-effect {
  width: 10%;
}
.outcomes-container tr.outcome-accp-row .risk-with-control,
.outcomes-container tr.outcome-accp-row td:nth-child(11) {
  width: 8%;
}
.outcomes-container tr.outcome-accp-row .risk-difference-with-intervention {
  width: auto;
}
.outcomes-container tr.outcome-sof-accp-row .outcome-cell {
  width: auto;
}
.outcomes-container tr.outcome-sof-accp-row .no-of-participants {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .quality {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .relative-effect {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .risk-with-control,
.outcomes-container tr.outcome-sof-accp-row .control {
  width: 12%;
}
.outcomes-container tr.outcome-sof-accp-row .absolute-effect {
  width: 10%;
}
.outcomes-container tr.outcome-sof-accp-row .risk-difference-with-intervention {
  width: 12%;
}
.outcomes-container tr.outcome-sof-accp-row .impact {
  width: 30%;
}
.outcomes-container tr.outcome-diag-row th.diagnostic-effect,
.outcomes-container tr.outcome-diag-sof-row th.diagnostic-effect {
  word-break: break-word;
}
.outcomes-container tr.outcome-diag-row > th {
  word-break: break-word;
}
.outcomes-container tr.outcome-diag-row .diagnostic-outcome-label {
  width: auto;
}
.outcomes-container tr.outcome-diag-row .no-of-studies-patients {
  width: 6%;
}
.outcomes-container tr.outcome-diag-row .design-studies {
  width: 6%;
}
.outcomes-container tr.outcome-diag-row .risk-of-bias,
.outcomes-container tr.outcome-diag-row .indirectness,
.outcomes-container tr.outcome-diag-row .imprecision {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .inconsistency {
  min-width: 88px;
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .publication-bias {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .other-considerations {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .diagnostic-effect {
  width: 7%;
}
.outcomes-container tr.outcome-diag-row .quality {
  width: 6%;
}
.outcomes-container tr.outcome-diag-row .importance {
  width: 7%;
}
.outcomes-container tr.outcome-diag-sof-row .diagnostic-outcome-label {
  width: 20%;
}
.outcomes-container tr.outcome-diag-sof-row .no-of-participants-studies {
  width: 10%;
}
.outcomes-container tr.outcome-diag-sof-row .quality {
  width: 10%;
}
.outcomes-container tr.outcome-diag-sof-row .diagnostic-effect {
  width: 8%;
}
.outcomes-container tr.outcome-diag-sof-row .comments {
  width: 25%;
}
.outcomes-container .prognostic-table > th,
.outcomes-container tr.prognostic-outcome > th {
  word-break: break-word;
}
.outcomes-container .prognostic-table .no-of-studies,
.outcomes-container tr.prognostic-outcome .no-of-studies {
  width: 6%;
}
.outcomes-container .prognostic-table .design-studies,
.outcomes-container tr.prognostic-outcome .design-studies,
.outcomes-container .prognostic-table .risk-of-bias,
.outcomes-container tr.prognostic-outcome .risk-of-bias,
.outcomes-container .prognostic-table .inconsistency,
.outcomes-container tr.prognostic-outcome .inconsistency,
.outcomes-container .prognostic-table .indirectness,
.outcomes-container tr.prognostic-outcome .indirectness,
.outcomes-container .prognostic-table .imprecision,
.outcomes-container tr.prognostic-outcome .imprecision {
  width: 7%;
}
.outcomes-container .prognostic-table .other-considerations,
.outcomes-container tr.prognostic-outcome .other-considerations {
  width: 8%;
}
.outcomes-container .prognostic-table .rate,
.outcomes-container tr.prognostic-outcome .rate,
.outcomes-container .prognostic-table .events,
.outcomes-container tr.prognostic-outcome .events,
.outcomes-container .prognostic-table .relative-effect,
.outcomes-container tr.prognostic-outcome .relative-effect {
  width: 10%;
}
.outcomes-container .prognostic-table .quality,
.outcomes-container tr.prognostic-outcome .quality {
  width: 8%;
}
.outcomes-container .prognostic-table .importance,
.outcomes-container tr.prognostic-outcome .importance {
  width: 10%;
}
.outcomes-container .prognostic-table .importance div.table-menu,
.outcomes-container tr.prognostic-outcome .importance div.table-menu {
  display: inline-block;
  float: right;
  border-left: 1px solid #bfbfbf;
  height: 100%;
}
.outcomes-container .prognostic-table .importance div.table-menu button,
.outcomes-container tr.prognostic-outcome .importance div.table-menu button {
  margin-top: 90%;
}
.outcomes-container .prognostic-table .importance span.table-menu,
.outcomes-container tr.prognostic-outcome .importance span.table-menu {
  display: inline-block;
  padding-top: 25%;
  height: 100%;
}
.outcomes-container .prevs-count-1 tr.outcome-diag-row .diagnostic-effect {
  width: 7%;
}
.outcomes-container .prevs-count-2 tr.outcome-diag-row .diagnostic-effect {
  width: 5%;
}
.outcomes-container .prevs-count-3 tr.outcome-diag-row .diagnostic-effect {
  width: 5%;
}
.outcomes-container table.lang-de .risk-of-bias,
.outcomes-container table.lang-de .indirectness,
.outcomes-container table.lang-de .inconsistency,
.outcomes-container table.lang-de .imprecision {
  width: 7%;
}
.outcomes-container table.lang-de .quality {
  width: 9%;
}
.outcomes-container table.lang-de .publication-bias {
  width: 9%;
}
.outcomes-container table.lang-de .design-studies {
  width: 8%;
}
.outcomes-container #isof-content {
  border: 0;
  width: 100%;
}
.isof-view {
  padding: 30px;
  background-color: #f6f6f6;
}
.isof-view .message {
  font-size: 16px;
  font-size: 1.6rem;
}
.isof-view .test-dropdown {
  margin-bottom: 10px;
}
