@media screen and (min-height: 690px) {
  .new-user-view > div:nth-child(1) {
    min-height: 235px;
    min-width: 555px;
  }
  .notifier.notifier-theme-gdt.new-user-screen > div h2 {
    margin: 15px 0px 30px;
  }
  .capabilities-view #main-menu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 10px 20px;
    text-shadow: none;
  }
  .capabilities-view #main-menu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
  .capabilities-view #submenu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 10px 20px;
    text-shadow: none;
  }
  .capabilities-view #submenu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
}
@media screen and (max-height: 689px) {
  .new-user-view > div:nth-child(1) {
    min-height: 196px;
    min-width: 555px;
  }
  .notifier.notifier-theme-gdt.new-user-screen > div h2 {
    margin: 10px 0px 15px;
  }
  .capabilities-view #main-menu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 7px 20px;
    text-shadow: none;
  }
  .capabilities-view #main-menu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
  .capabilities-view #submenu button {
    background: none;
    border: none;
    margin: 0;
    text-align: left;
    font-size: 15px;
    font-size: 1.5rem;
    padding: 7px 20px;
    text-shadow: none;
  }
  .capabilities-view #submenu button:hover {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    background: none;
    color: #8b8d90;
    text-shadow: none;
  }
}
.welcome-view {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: box !important;
  display: flex !important;
  height: 100%;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.welcome-view button {
  font-size: 15px;
  font-size: 1.5rem;
}
.welcome-view h2 {
  font-size: 18px;
  font-size: 1.8rem;
  margin-top: 0;
}
.welcome-view button {
  padding: 1.2rem 0;
}
.welcome-view button:hover:not(:disabled) {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  color: #8b8d90;
}
.welcome-view .content-box {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  background: #fff;
}
.welcome-view button.continue {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
  position: absolute;
  right: 0px;
  z-index: 3;
  background: #c9dac2;
  color: #2ba82e;
}
.welcome-view button.continue:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.welcome-view button.continue:hover {
  background: #eef4ec;
  color: #69bf6b;
}
.welcome-view .tabs-container,
.welcome-view .tabs-view,
.welcome-view .tab-container {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.welcome-view .tabs-container {
  font-size: 15px;
  font-size: 1.5rem;
}
.welcome-view .tabs-container .tab-elems {
  position: relative;
  border: none;
}
.welcome-view .tabs-container .tab-elems:after {
  position: absolute;
  content: '';
  width: 100%;
  left: 0;
  bottom: 0;
  border-bottom: 1px solid #bfbfbf;
  z-index: 1;
}
.welcome-view .tabs-container .tab-elems .tab-elems-line {
  border: none;
}
.welcome-view .tabs-container .tab-elem {
  position: relative;
  z-index: 0;
  background: none;
  color: #a7a7a7;
  border: none;
}
.welcome-view .tabs-container .tab-elem.current-tab {
  z-index: 2;
  font-weight: bold;
  background: #eaeaea;
  color: #2d3236;
  border: 1px solid #bfbfbf;
  border-bottom-color: #eaeaea;
}
.welcome-view .tabs-container .tab-container {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  padding: 0;
  background: #eaeaea;
  border: 1px solid #bfbfbf;
  border-top: none;
}
.welcome-view .caption,
.welcome-view .caption:link,
.welcome-view .caption:visited,
.welcome-view .caption:hover,
.welcome-view .caption:active {
  color: inherit;
  text-decoration: none;
}
.welcome-view .caption:hover {
  text-decoration: underline;
}
.welcome-view .changelog-view {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.welcome-view .changelog-view .centered {
  padding-top: 30px;
  text-align: center;
}
.welcome-view .changelog-view table {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.welcome-view .changelog-view table,
.welcome-view .changelog-view tr,
.welcome-view .changelog-view td,
.welcome-view .changelog-view th {
  border: none;
}
.welcome-view .changelog-view tr {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.welcome-view .changelog-view td,
.welcome-view .changelog-view th {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 1.2rem 2rem 1.2rem 0;
}
.welcome-view .changelog-view td:first-child,
.welcome-view .changelog-view th:first-child {
  padding-left: 2rem;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
}
.welcome-view .changelog-view td:last-child,
.welcome-view .changelog-view th:last-child {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 200px;
  -ms-flex: 0 0 200px;
  flex: 0 0 200px;
}
.welcome-view .changelog-view th {
  font-weight: bold;
  text-align: left;
}
.welcome-view .changelog-view thead {
  background: #eaeaea;
  padding-right: 10px;
}
.welcome-view .changelog-view tbody {
  overflow-y: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1 1;
  -ms-flex: 1 1;
  flex: 1 1;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
}
.welcome-view .changelog-view tbody tr {
  background: #ddd;
}
.welcome-view .changelog-view tbody tr:nth-child(2n) {
  background: #eaeaea;
}
.welcome-view .changelog-view tbody td:nth-child(2) {
  width: 175px;
}
.welcome-view .changelog-view button.projects-list {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
  margin: 10px;
  background: #cecece;
  color: #5b666e;
}
.welcome-view .changelog-view button.projects-list:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.welcome-view .changelog-view button.projects-list:hover {
  background: #e2e2e2;
}
.new-user-view {
  padding: 1rem;
  color: #2d3236;
  min-width: 550px;
}
.new-user-view .youtube-container {
  float: left;
  margin-right: 30px;
}
.welcome-start-new {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.welcome-start-new > div {
  padding: 3rem 2rem;
}
.welcome-start-new .project-types {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-right: 100px;
}
.welcome-learn-view {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 3rem 2rem;
}
.welcome-learn-view .support,
.welcome-learn-view .learn {
  margin-right: 9rem;
}
.welcome-learn-view menu[type='toolbar'] {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 250px;
  -ms-flex: 0 0 250px;
  flex: 0 0 250px;
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 0;
}
.welcome-learn-view menu[type='toolbar'] button {
  text-align: left;
}
.welcome-learn-view menu[type='toolbar'] a {
  color: #2d3236;
  text-decoration: none;
}
.welcome-learn-view menu[type='toolbar'] a:hover {
  color: #8b8d90;
}
.welcome-learn-view .buttons-block {
  text-align: center;
  margin-top: 3rem;
}
.welcome-learn-view .buttons-block button {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
  padding: 7px 20px;
  color: #5b666e;
}
.welcome-learn-view .buttons-block button:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.welcome-learn-view .buttons-block button:hover {
  background: #e2e2e2;
}
.welcome-learn-view #dont-show-again {
  display: block;
  float: right;
  font-size: 13px;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.welcome-learn-view #dont-show-again button {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
}
.welcome-learn-view #dont-show-again button:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.welcome-learn-view #dont-show-again button:hover {
  text-decoration: underline;
  color: #2d3236;
}
.notifier.notifier-theme-gdt.new-user-screen > div .notifier-title {
  margin: 15px 0 0;
  padding: 0;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 2.6rem;
}
.notifier.notifier-theme-gdt.new-user-screen > div .unstyled-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.notifier.notifier-theme-gdt.new-user-screen > div .unstyled-list li button {
  padding: 5px 0px 15px;
}
.capabilities-view {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.capabilities-view #main-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.capabilities-view #main-menu button {
  width: 100%;
}
.capabilities-view #main-menu button.selected {
  background: #ddd;
}
.capabilities-view #main-menu button:after {
  content: '\203a';
  color: #a7a7a7;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2rem;
  float: right;
  padding-left: 10px;
}
.capabilities-view #main-menu ul {
  display: none;
}
.capabilities-view #submenu {
  background: #ddd;
}
.capabilities-view #submenu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.capabilities-view #submenu ul li.separated {
  border-top: 1px solid #bfbfbf;
}
.capabilities-view #submenu button {
  width: 350px;
  font-size: 16px;
}
.welcome-learn-view .capabilities h2 {
  text-indent: 2rem;
}
.welcome-learn-view .capabilities .capabilities-view #main-menu button.selected {
  background: #d0d0d0;
}
.welcome-learn-view .capabilities #submenu {
  background: #d0d0d0;
}
.notifier-dialog.info-box .notifier-inner {
  overflow: auto;
}
.notifier.notifier-theme-gdt.new-user-screen > div {
  padding-bottom: 10px;
}
.notifier.notifier-theme-gdt.new-user-screen > div .notifier-message .new-user-view {
  padding-bottom: 0px;
}
.notifier.notifier-theme-gdt.new-user-screen > div .notifier-message .new-user-view .get-started {
  width: 110px;
  margin: 0 auto;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-title {
  margin: 0;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns {
  background: inherit;
  border-top: none;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns button {
  background: none;
  border: none;
  margin: 0;
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 10px 20px;
  text-shadow: none;
  text-align: center;
  background: #cecece;
  color: #5b666e;
  margin-right: 10px;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns button:hover {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: none;
  color: #8b8d90;
  text-shadow: none;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns:hover {
  background: #e2e2e2;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns.finish {
  background: #c9dac2;
  color: #2ba82e;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns.finish:hover {
  background: #eef4ec;
  color: #69bf6b;
}
.notifier.notifier-theme-gdt.notifier-dialog.new-modal-dialog .notifier-btns.finish:disabled {
  background: #d9e2d6;
  color: #80c582;
}
