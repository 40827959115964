.buttons-row .button-container {
  position: relative;
  min-height: 1px;
  padding: 0 5px;
  float: left;
}
.buttons-row.sticked-bottom {
  position: fixed;
  bottom: 10px;
  padding-top: 5px;
  background-color: #fff;
}
