#outcomes-export-dialog {
  font-size: 14px;
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}
#outcomes-export-dialog .title {
  font-weight: normal;
  margin: 0;
  padding: 0 15px 10px;
  font-size: 24px;
  text-align: center;
}
#outcomes-export-dialog .choose-format-title,
#outcomes-export-dialog .choose-outcomes-title,
#outcomes-export-dialog .choose-appendices-title,
#outcomes-export-dialog .choose-orientation-title {
  display: block;
  font-weight: bold;
  padding: 10px 15px;
}
#outcomes-export-dialog .choose-format-title,
#outcomes-export-dialog .choose-orientation-title {
  padding-bottom: 5px;
}
#outcomes-export-dialog label {
  padding: 2px 15px;
}
#outcomes-export-dialog .choose-format label,
#outcomes-export-dialog .choose-orientation label {
  display: block;
}
#outcomes-export-dialog .choose-format label input[type="radio"],
#outcomes-export-dialog .choose-orientation label input[type="radio"] {
  position: relative;
  top: 3px;
}
#outcomes-export-dialog .choose-format label .question-mark-sign,
#outcomes-export-dialog .choose-orientation label .question-mark-sign {
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  vertical-align: top;
}
#outcomes-export-dialog .choose-format label .question-mark-sign:before,
#outcomes-export-dialog .choose-orientation label .question-mark-sign:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#outcomes-export-dialog .icon-orientation-landscape,
#outcomes-export-dialog .icon-orientation-portrait {
  font-size: 18px;
  margin-left: 3px;
}
#outcomes-export-dialog .icon-orientation-landscape:after {
  font-family: 'gdt';
  content: '\e030';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#outcomes-export-dialog .icon-orientation-portrait:after {
  font-family: 'gdt';
  content: '\e031';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#outcomes-export-dialog fieldset {
  margin-bottom: 20px;
  text-align: center;
}
#outcomes-export-dialog .buttons {
  margin: 0px 15px;
}
#outcomes-export-dialog .appendices-to-export,
#outcomes-export-dialog .outcomes-to-export {
  max-width: 550px;
  max-height: 450px;
  overflow-y: auto;
}
#outcomes-export-dialog .appendices-to-export ul,
#outcomes-export-dialog .outcomes-to-export ul {
  background: #eaeaea;
  padding: 0;
  margin: 0;
  list-style: none;
}
#outcomes-export-dialog .appendices-to-export ul li,
#outcomes-export-dialog .outcomes-to-export ul li {
  position: relative;
  line-height: 20px;
  padding: 5px 15px;
}
#outcomes-export-dialog .appendices-to-export ul li.selected,
#outcomes-export-dialog .outcomes-to-export ul li.selected {
  background-color: #ddd;
}
#outcomes-export-dialog .appendices-to-export ul li input,
#outcomes-export-dialog .outcomes-to-export ul li input {
  position: relative;
  top: 6px;
  float: left;
}
#outcomes-export-dialog .appendices-to-export ul li input[type="checkbox"],
#outcomes-export-dialog .outcomes-to-export ul li input[type="checkbox"] {
  background-color: #fff;
}
#outcomes-export-dialog .appendices-to-export ul li input[type="checkbox"]:checked,
#outcomes-export-dialog .outcomes-to-export ul li input[type="checkbox"]:checked {
  background-color: #ddd;
}
#outcomes-export-dialog .appendices-to-export ul li label,
#outcomes-export-dialog .outcomes-to-export ul li label {
  display: block;
  padding-left: 20px;
}
