.box {
  display: none;
  background: #f6f6f6;
  border: 1px solid #bfbfbf;
  padding: 10px 0px;
  font-size: 14px;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  z-index: 1000;
  position: absolute;
  margin: 0;
  min-width: 200px;
  overflow: hidden;
}
.box span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
.box ul {
  position: relative;
}
.box ul li {
  background: #f6f6f6;
}
.box .edit-input-block.active {
  background-color: #ddd;
}
.box .edit-input-block.active input,
.box .edit-input-block.active textarea {
  border: 1px #9bbde0 solid;
}
.box .edit-input-block.active div.empty {
  background-color: #f6f6f6;
}
.box .v-top {
  vertical-align: top;
}
.box .v-top * {
  vertical-align: top;
}
.box .v-bottom {
  vertical-align: bottom;
}
.box .v-bottom * {
  vertical-align: bottom;
}
.box legend {
  font-weight: bold;
}
.box div.percent {
  position: relative;
}
.box div.percent:after {
  position: absolute;
  top: 7px;
  right: 15px;
  float: right;
  content: '%';
}
.box input.form-control {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  min-width: 45px;
}
.box .text-with-limit textarea {
  padding-right: 0px;
  padding-top: 15px;
}
.box textarea.cell-edit-textarea {
  width: 100%;
  font-size: 14px;
  line-height: 19px;
}
.box label {
  font-size: 14px;
  line-height: 19px;
}
.box label.checkbox {
  position: relative;
  width: 100%;
}
.box label.checkbox input[type="checkbox"] {
  position: absolute;
  top: 9px;
  right: 0px;
}
.box label.footnote-validation {
  color: #f00;
}
.box .content {
  border: none;
  margin: 0;
  padding: 0;
}
.box .content label {
  margin-left: 5px;
  display: inline-block;
  width: 170px;
  line-height: 30px;
}
.box .content label.long {
  width: auto;
}
.box .content label.middle {
  width: auto;
  margin: 0px 5px;
}
.box .content label.legend {
  font-weight: bold;
}
.box .content label span.text-right {
  float: right;
}
.box .content input {
  width: auto;
}
.box .content input[type=checkbox] {
  width: 12px;
}
.box .content input.number {
  width: 75px;
}
.box .content input.number.short {
  width: 45px !important;
}
.box .content .sup,
.box .content .inline {
  display: inline-block;
}
.box .content .sup .selection-list-value,
.box .content .inline .selection-list-value {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  top: -2px;
}
.box .content .buttons button {
  width: 100%;
}
.box .content .outcomeLabel .no-of-participants-embeded label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded label {
  width: 145px;
}
.box .content .outcomeLabel .no-of-participants-embeded input,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded input,
.box .content .noOfParticipantsStudies .no-of-participants-embeded input,
.box .content .outcomeLabel .no-of-participants-embeded .selection-list-value,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .selection-list-value,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .selection-list-value {
  top: 0px;
  width: 213px;
  max-width: 215px;
}
.box .content .outcomeLabel .no-of-participants-embeded .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded .observational-studies-list {
  padding-left: 154px;
  margin-top: 5px;
}
.box .content .outcomeLabel .no-of-participants-embeded.ACCP label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.ACCP label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.ACCP label,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_ACCP label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_ACCP label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_ACCP label,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v3 label,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v3 label,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v3 label {
  width: 170px;
}
.box .content .outcomeLabel .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.ACCP .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_ACCP .observational-studies-list,
.box .content .outcomeLabel .no-of-participants-embeded.SOF_v3 .observational-studies-list,
.box .content .noOfParticipantsStudiesFollowUp .no-of-participants-embeded.SOF_v3 .observational-studies-list,
.box .content .noOfParticipantsStudies .no-of-participants-embeded.SOF_v3 .observational-studies-list {
  padding-left: 179px;
}
.box .content .outcomeLabel input,
.box .content .noOfParticipantsStudiesFollowUp input,
.box .content .noOfParticipantsStudies input {
  width: 213px;
}
.box .content .outcomeLabel input.half,
.box .content .noOfParticipantsStudiesFollowUp input.half,
.box .content .noOfParticipantsStudies input.half {
  width: 90px;
}
.box .content .quality label {
  width: 160px;
}
.box .content .quality .selection-list-value {
  width: 175px;
  max-width: 175px;
}
.box .content .quality .col-5-5 {
  display: inline-block;
  width: 175px;
}
.box .content .quality .col-1.sup {
  display: inline-block;
}
.box .content .relativeEffect label {
  width: 125px;
}
.box .content .relativeEffect label.middle {
  width: auto;
  margin: 0px 5px;
}
.box .content .relativeEffect .select-input-container {
  min-width: 75px;
}
.box .content .otherConsiderations .selection-list-value {
  min-width: 145px;
}
.box .content .intervention label {
  width: 100px;
}
.box .content .intervention input {
  width: 150px;
}
.box .content .narrativeDesc textarea {
  min-width: 300px;
  max-width: 300px;
}
.box .content .compound-edit.comments .text-with-limit {
  display: inline-block;
}
.box .content .sup {
  vertical-align: top;
  padding: 0;
}
.box .edit-label {
  margin: 0px 10px;
  font-weight: bold;
}
.box span.close {
  color: #a6afb6;
  width: 10px;
  font-size: 25px;
  line-height: 20px;
  float: right;
  padding-right: 2px;
  cursor: pointer;
}
.box .btn-popup {
  text-align: center;
  cursor: pointer;
  margin: 0px 10px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
  color: #5c5d5f;
  background: #cdcdcd;
}
.box .btn-popup:hover {
  background: #ddd;
}
.box .buttons .apply {
  background-color: #c9dac2;
  color: #17a01b;
}
.box .buttons .btn {
  line-height: 28px;
  width: 50%;
}
.box.footnotes-box {
  width: 600px;
}
.box.footnotes-box .scrolled {
  width: 100%;
}
.box.footnotes-box .clearfix {
  min-height: 20px;
}
.box.footnotes-box button.edit,
.box.footnotes-box button.save,
.box.footnotes-box button.delete {
  margin-top: 4px;
  float: right;
  padding-left: 15px;
}
.box.footnotes-box .edit-footnote {
  display: none;
}
.box.footnotes-box .empty-footnotes {
  text-align: center;
}
.box .edit-options-list,
.box .footnotes-list {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0;
  padding-top: 5px;
}
.box .edit-options-list li,
.box .footnotes-list li {
  padding: 0 10px;
  height: 32px;
}
.box .edit-options-list li.edit-option,
.box .footnotes-list li.edit-option {
  white-space: nowrap;
  line-height: 30px;
  cursor: pointer;
}
.box .edit-options-list li.clear-option,
.box .footnotes-list li.clear-option {
  margin-top: 10px;
  height: 28px;
  line-height: 28px;
}
.box .edit-options-list li.clear-option.active,
.box .footnotes-list li.clear-option.active,
.box .edit-options-list li.clear-option:hover,
.box .footnotes-list li.clear-option:hover {
  background: #ddd;
}
.box .edit-options-list li input[type="checkbox"],
.box .footnotes-list li input[type="checkbox"] {
  float: left;
  margin-top: 8px;
  margin-right: 10px;
}
.box .edit-options-list li input[type="text"],
.box .footnotes-list li input[type="text"] {
  height: 30px;
  line-height: 30px;
}
.box .edit-options-list li label,
.box .footnotes-list li label {
  line-height: 30px;
  margin: 0;
  padding: 0;
  display: block;
  width: 500px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.box .edit-options-list li .footnote-input-field,
.box .footnotes-list li .footnote-input-field {
  width: 485px;
  font-size: 14px;
}
.box .edit-options-list li:hover,
.box .footnotes-list li:hover {
  background: #ddd;
}
.box .edit-options-list li.active,
.box .footnotes-list li.active {
  background: #eaeaea;
}
.box .edit-options-list li.current,
.box .footnotes-list li.current,
.box .edit-options-list li.checked,
.box .footnotes-list li.checked {
  background: #ddd;
}
.box .select-input-container.other {
  min-width: 138px;
}
.box .select-input-container.other span.selection-list-value {
  width: 71px !important;
  display: inline-block;
}
.box .select-input-container.other input {
  width: 71px;
}
.box .new-footnote-button {
  margin-top: 10px;
}
