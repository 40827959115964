.feedback-view {
  width: 50rem;
}
.feedback-view label,
.feedback-view textarea {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.feedback-view textarea {
  height: 10rem;
}
