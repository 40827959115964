.voting-view .upper-block {
  margin-bottom: 30px;
}
.voting-view .upper-block .info {
  line-height: 30px;
}
.voting-view .upper-block .send-refresh-buttons {
  float: right;
}
.voting-view .upper-block .send-refresh-buttons button {
  margin-left: 5px;
  min-width: 100px;
}
.voting-view .tabpanel .nav {
  margin: 0;
  border-bottom: 1px solid #bfbfbf;
}
.voting-view .tabpanel .nav.nav-tabs {
  padding: 0;
  margin: 0;
  list-style: none;
}
.voting-view .tabpanel .nav.nav-tabs li {
  width: 16%;
  text-align: center;
  float: left;
  cursor: pointer;
  margin-bottom: -1px;
  margin-right: 5px;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.voting-view .tabpanel .nav.nav-tabs li a,
.voting-view .tabpanel .nav.nav-tabs li a:hover,
.voting-view .tabpanel .nav.nav-tabs li a:active,
.voting-view .tabpanel .nav.nav-tabs li a:visited {
  outline: 0;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #c4c4c4;
}
.voting-view .tabpanel .nav.nav-tabs li.active {
  border: 1px solid #bfbfbf;
  background-color: #e9e9e9;
  border-bottom-color: #e9e9e9;
  font-weight: bold;
}
.voting-view .tabpanel .nav.nav-tabs li.active a {
  color: #2d3236;
}
.voting-view .tabpanel .nav:after {
  clear: both;
  display: table;
  content: " ";
}
.voting-view .tabpanel .tab-content {
  background-color: #e9e9e9;
  border: 1px solid #bfbfbf;
  border-top: none;
  padding: 10px;
  min-height: 200px;
}
.voting-view .members-list .checkbox-with-text span {
  margin-left: 10px;
}
.voting-view .members-list .member-item {
  margin: 5px 0;
}
.voting-view .message-panel {
  margin-top: 30px;
}
.voting-view .message-panel .message-container {
  border: 1px solid #bfbfbf;
}
.voting-view .message-panel .message-container div[contenteditable] {
  min-height: 150px;
  padding: 5px;
}
.voting-modal {
  width: 440px;
  text-align: center;
}
