.rm5-verification .description {
  margin: 10px 0;
  background-color: #f7bf9c;
}
.rm5-verification .questions-list {
  max-height: 250px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  background: #ddd;
}
.rm5-verification .questions-list .question-text {
  font-weight: bold;
}
.rm5-verification .outcome-radios {
  float: right;
}
