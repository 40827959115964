.footnotes-container {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.footnotes-container .footnotes {
  width: 100%;
}
.footnotes-container .footnotes .footnotes-bar {
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  background-color: #ddd;
  font-size: 16px;
  position: relative;
}
.footnotes-container .footnotes .footnotes-bar .arrow {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 22px;
}
.footnotes-container .footnotes .footnotes-bar.closed .arrow:before {
  font-family: 'gdt';
  content: '\e017';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.footnotes-container .footnotes .footnotes-bar.closed .arrow * {
  margin-left: 0.5rem;
}
.footnotes-container .footnotes .footnotes-bar.opened .arrow:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.footnotes-container .footnotes .footnotes-bar.opened .arrow * {
  margin-left: 0.5rem;
}
.footnotes-container .footnotes .footnotes-block {
  height: 250px;
  background-color: #fff;
  overflow-y: auto;
  padding: 10px;
}
.footnotes-container .footnotes .footnotes-list {
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  counter-reset: li;
  position: relative;
}
.footnotes-container .footnotes .footnotes-list li {
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  position: relative;
  font-size: 14px;
}
.footnotes-container .footnotes .footnotes-list li div.note {
  position: relative;
  height: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.footnotes-container .footnotes .footnotes-list li .ellipsis-wrapper {
  line-height: 14px;
}
.footnotes-container .footnotes .footnotes-list li span.note-text {
  line-height: 14px;
}
.footnotes-container .footnotes .footnotes-list li input[type="text"] {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  margin-top: 5px;
  width: 90%;
}
.footnotes-container .footnotes .footnotes-list li:before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  text-align: center;
}
.footnotes-container .footnotes .footnotes-list li.active {
  background-color: #fdf1c3;
}
.footnotes-container .footnotes .footnotes-list button.edit,
.footnotes-container .footnotes .footnotes-list button.save,
.footnotes-container .footnotes .footnotes-list button.delete {
  float: right;
  background-position: center center;
  height: 40px;
  width: 36px;
}
.footnotes-container .footnotes .footnotes-list .edit-footnote {
  display: none;
  line-height: 30px;
}
.footnotes-container .footnotes .dragged-footnote {
  font-size: 14px;
  background-color: #fdf1c3;
  line-height: 30px;
  padding: 0 15px;
}
