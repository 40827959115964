.popup-menu.help-popup {
  display: block;
  width: 625px;
  padding: 20px;
}
.popup-menu.help-popup > div {
  clear: both;
}
.popup-menu.help-popup > hr {
  clear: both;
  border: 0;
  border-bottom: 1px solid #ccc;
  padding-top: 20px;
}
.popup-menu.help-popup ul li:hover {
  background: #d2dfed;
}
.popup-menu.help-popup .capabilities-view #main-menu li {
  padding: 0;
}
.popup-menu.help-popup .capabilities-view #main-menu button.selected,
.popup-menu.help-popup .capabilities-view #main-menu + #submenu,
.popup-menu.help-popup .capabilities-view #main-menu + #submenu li {
  background: #d2dfed;
}
.popup-menu.help-popup .capabilities-view #submenu button {
  white-space: initial;
}
.popup-menu.help-popup .dot {
  border: 0;
}
