.tabs-view .tab-elems {
  min-height: 42px;
}
.tabs-view .tab-container {
  overflow-y: auto;
}
.projects-menu {
  padding-bottom: 10px;
}
.projects-menu .new-projects-list {
  max-height: 80vh;
  overflow-y: auto;
}
.projects-list {
  display: block;
}
.projects-list .standard-table {
  background-color: #e9e9e9;
  border: 0;
  width: 100%;
}
.projects-list .standard-table thead th,
.projects-list .standard-table tbody,
.projects-list .standard-table td {
  border: 0;
}
.projects-list .standard-table th {
  text-align: left;
}
.projects-list .standard-table th.sort {
  cursor: pointer;
}
.projects-list .standard-table th,
.projects-list .standard-table td {
  overflow: hidden;
}
.projects-list .standard-table th:nth-child(1),
.projects-list .standard-table td:nth-child(1),
.projects-list .standard-table th:nth-child(2),
.projects-list .standard-table td:nth-child(2),
.projects-list .standard-table th:nth-child(6),
.projects-list .standard-table td:nth-child(6) {
  width: 5%;
}
.projects-list .standard-table th:nth-child(4),
.projects-list .standard-table td:nth-child(4) {
  width: 20%;
}
.projects-list .standard-table th:nth-child(5),
.projects-list .standard-table td:nth-child(5) {
  width: 10%;
}
.projects-list .standard-table tbody tr {
  line-height: 5rem;
}
.projects-list .standard-table tbody:nth-child(odd) {
  background-color: #ddd;
}
.projects-list .standard-table tbody:hover {
  background-color: #fff;
}
.projects-list .standard-table .projects-list-item input {
  width: 80%;
}
.projects-list .standard-table .projects-list-item td.toggle-button {
  padding-left: 10px;
}
.projects-list .standard-table .projects-list-item td.toggle-button .arrow-down,
.projects-list .standard-table .projects-list-item td.project-toolbar button,
.projects-list .standard-table .projects-list-item tr.questions-list {
  display: none;
}
.projects-list .standard-table .projects-list-item.opened td.toggle-button .arrow-down,
.projects-list .standard-table .projects-list-item.opened tr.questions-list {
  display: table-row;
}
.projects-list .standard-table .projects-list-item.opened td.toggle-button .arrow-right {
  display: none;
}
.projects-list .standard-table .projects-list-item.opened td.project-toolbar button {
  display: block;
  left: 33%;
  position: relative;
}
.projects-list .standard-table .projects-list-item td .ellipsis-wrapper {
  display: inline-block;
}
.projects-list .questions-list {
  border: 1px;
  width: 100%;
}
.projects-list .questions-list tbody:hover {
  background-color: inherit;
}
.projects-list .questions-list tr:nth-child(odd) {
  background-color: #ddd;
}
.projects-list .questions-list tr:hover {
  background-color: #fff;
}
.projects-list .questions-list td.questions-list-container {
  padding-bottom: 10px;
}
#header .projects-list-item th:nth-child(1),
#header .projects-list-item td:nth-child(1),
#header .projects-list-item th:nth-child(2),
#header .projects-list-item td:nth-child(2),
#header .projects-list-item th:nth-child(4),
#header .projects-list-item td:nth-child(4) {
  width: 5%;
}
#header .projects-list-item th:nth-child(3),
#header .projects-list-item td:nth-child(3) {
  width: 85%;
}
#header .projects-list-item th:nth-child(4) button,
#header .projects-list-item td:nth-child(4) button {
  position: relative;
  left: 33%;
}
#header .projects-list-item td.toggle-button .arrow-down {
  display: none;
}
#header .projects-list-item.opened td.toggle-button .arrow-right {
  display: none;
}
#header .projects-list-item.opened td.toggle-button .arrow-down {
  display: block;
}
#header .projects-list-item button {
  width: auto;
}
