#sidebar li {
  position: relative;
}
#sidebar a {
  display: block;
  width: 100%;
  height: 40px;
  line-height: 41px;
  background: #dfdfdf;
  border-bottom: 1px solid #bbb;
  font-size: 13px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
#sidebar a,
#sidebar a:link,
#sidebar a:visited,
#sidebar a:hover,
#sidebar a:active {
  color: inherit;
  text-decoration: none;
}
#sidebar a:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar a.disabled:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ccc;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
#sidebar a.selected + .submenu {
  display: block;
}
#sidebar .submenu {
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
}
#sidebar .submenu li,
#sidebar .submenu a {
  height: 38px;
}
#sidebar .submenu a {
  line-height: 39px;
}
#sidebar .submenu li {
  padding-left: 5px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
#sidebar .submenu a {
  padding: 0 1.4rem;
  position: relative;
}
#sidebar .submenu a.current {
  background: #f6f6f6;
}
#sidebar .submenu .mCSB_scrollTools {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  -ms-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}
#sidebar .submenu:hover .mCSB_scrollTools {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
#sidebar .administration {
  background: #dfdfdf;
}
#sidebar .administration:before {
  font-family: 'gdt';
  content: '\e038';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .administration * {
  margin-left: 0.5rem;
}
#sidebar .administration:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .administration.selected,
#sidebar .administration:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .tasks {
  background: #dfdfdf;
}
#sidebar .tasks:before {
  font-family: 'gdt';
  content: '\e010';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .tasks * {
  margin-left: 0.5rem;
}
#sidebar .tasks:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .tasks.selected,
#sidebar .tasks:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .team {
  background: #dfdfdf;
}
#sidebar .team:before {
  font-family: 'gdt';
  content: '\e006';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .team * {
  margin-left: 0.5rem;
}
#sidebar .team:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .team.selected,
#sidebar .team:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .scope {
  background: #dfdfdf;
}
#sidebar .scope:before {
  font-family: 'gdt';
  content: '\e011';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .scope * {
  margin-left: 0.5rem;
}
#sidebar .scope:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .scope.selected,
#sidebar .scope:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .document-sections {
  background: #dfdfdf;
}
#sidebar .document-sections:before {
  font-family: 'gdt';
  content: '\e005';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .document-sections * {
  margin-left: 0.5rem;
}
#sidebar .document-sections:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .document-sections.selected,
#sidebar .document-sections:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .prognosis {
  background: #dfdfdf;
}
#sidebar .prognosis:before {
  font-family: 'gdt';
  content: '\e032';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .prognosis * {
  margin-left: 0.5rem;
}
#sidebar .prognosis:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .prognosis.selected,
#sidebar .prognosis:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .evidence-syntheses {
  background: #dfdfdf;
}
#sidebar .evidence-syntheses:before {
  font-family: 'gdt';
  content: '\e013';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .evidence-syntheses * {
  margin-left: 0.5rem;
}
#sidebar .evidence-syntheses:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .evidence-syntheses.selected,
#sidebar .evidence-syntheses:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar .dissemination {
  background: #dfdfdf;
}
#sidebar .dissemination:before {
  font-family: 'gdt';
  content: '\e005';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#sidebar .dissemination * {
  margin-left: 0.5rem;
}
#sidebar .dissemination:before {
  display: block;
  float: left;
  font-size: 14px;
  line-height: 41px;
  margin-left: 10px;
  margin-right: 3px;
  width: 20px;
  position: relative;
}
#sidebar .dissemination.selected,
#sidebar .dissemination:hover:not(.disabled) {
  background: #f6f6f6;
}
#sidebar.alternate .submenu a {
  padding: 0.4rem 1.4rem 0.3rem 1.4rem;
  font-size: 11px;
  font-size: 1.1rem;
  text-transform: none;
  line-height: 1.1em;
  display: inline-block;
  vertical-align: middle;
  white-space: pre-line;
}
#sidebar.alternate .recommendations {
  background: url("../images/recommendations.png") 8px 60% no-repeat, -webkit-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, -moz-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, -o-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, -ms-linear-gradient(top, #eaeaea, #5c5d5f);
  background: url("../images/recommendations.png") 8px 60% no-repeat, linear-gradient(to bottom, #eaeaea, #5c5d5f);
  padding-left: 40px;
  position: relative;
}
#sidebar.alternate .recommendations.selected,
#sidebar.alternate .recommendations:hover:not(.disabled) {
  background: url("../images/recommendations.png") 8px 60% no-repeat, #fff;
}
