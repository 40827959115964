.box .warning {
  background-color: #f7bf9c;
}
.box .content .edit-options-list .inner-options-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.box .content .edit-options-list .inner-options-list li.inactive-option {
  color: #909293;
  padding: 5px 7px;
  width: 100%;
}
.box .content .edit-options-list .inner-options-list li.edit-option {
  padding: 5px 17px;
}
.box .content .edit-options-list .inner-options-list li.edit-option.btn-popup {
  padding: 0;
}
.box .content .compound-edit {
  padding: 0 10px;
}
.box .content .compound-edit ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.box .content .compound-edit textarea {
  height: 150px;
}
.box .content .compound-edit textarea.short {
  height: 60px;
}
.box .content .compound-edit input[type="text"] {
  font-size: 14px;
}
.box .content .compound-edit input.short {
  width: 35px;
}
.box .content .compound-edit .text-with-limit {
  display: block;
}
.box .content .dich-effect-measure .selection-list-value {
  width: 150px;
  display: inline-block;
}
.box .content .short-select .selection-list-value {
  max-width: 75px;
}
.box .content .field {
  float: left;
  margin-right: 5px;
}
.box .content .number-input {
  width: 100px;
}
.box .content .sof-quality-info {
  display: inline-block;
  border-top: 1px solid #bfbfbf;
  padding: 2px 8px;
}
.input-error {
  border: 2px solid #f00;
}
.selection-list-value {
  display: block;
  max-width: 145px;
  width: 100%;
  padding-left: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  line-height: 30px;
  height: 30px;
  white-space: nowrap;
  vertical-align: bottom;
}
.selection-list-value .selection-text {
  display: block;
  padding-right: 25px;
  overflow: hidden;
  text-transform: lowercase;
}
.selection-list-value .selection-text.uppercase {
  text-transform: uppercase;
}
.selection-list-value:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  float: right;
  margin-right: 5px;
  font-size: 20px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
}
.selection-list-value.focused {
  border: 1px #9bbde0 solid;
}
.selection-list-value[disabled] {
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
