#document-sections-container .document-sections {
  width: 100%;
  display: inline-block;
}
#document-sections-container .document-sections iframe {
  width: 100%;
}
#document-sections-container .document-sections .te {
  display: inline-block;
  width: 78%;
  margin: 0;
}
#document-sections-container .sections {
  padding-left: 20px;
  display: inline-block;
  vertical-align: top;
  width: 20%;
}
#document-sections-container .sections .sections-list {
  font-size: 16px;
}
#document-sections-container .sections ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
#document-sections-container .sections ul h2 {
  font-size: 16px;
  font-size: 1.6rem;
}
#document-sections-container .sections ul li {
  cursor: pointer;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
}
#toolbar-container menu[type="toolbar"].document-sections-view button.edit {
  display: none;
}
