.dialog-box {
  display: none;
  background: #f6f6f6;
  border: 1px solid #bfbfbf;
  padding: 10px 0px;
  font-size: 14px;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
  z-index: 1000;
  position: absolute;
  margin: 0;
  min-width: 300px;
  max-width: 770px;
  overflow: hidden;
  width: 400px;
}
