.confirmation {
  background-color: #f6f6f6;
  padding: 10px;
}
.confirmation .buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.confirmation .buttons .apply {
  background-color: #c9dac2;
  color: #17a01b;
}
.confirmation .buttons .btn {
  flex: 1;
  margin-top: 10px;
  line-height: 28px;
}
.confirmation .buttons .btn:first-child {
  margin-right: 10px;
}
