#new-project-dialog {
  color: #2d3236;
}
#new-project-dialog fieldset > label {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 2rem;
}
#new-project-dialog input[type=text] {
  padding: 7px 10px;
}
#new-project-dialog .types {
  position: relative;
}
#new-project-dialog .types span.info-sign {
  position: absolute;
  color: #747c82;
  left: -2px;
  top: -2px;
  font-size: 1.6rem;
}
#new-project-dialog .types span.info-sign:before {
  font-family: 'gdt';
  content: '\e003';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#new-project-dialog .types span.info-sign * {
  margin-left: 0.5rem;
}
#new-project-dialog .types h3 {
  margin-left: 20px;
  font-size: 1.5rem;
}
#new-project-dialog .types label {
  display: block;
}
#new-project-dialog .types label.disabled {
  opacity: 0.3;
}
#new-project-dialog .types input[type=radio] {
  vertical-align: inherit;
  margin: 2px 15px 0 0;
  width: 11px;
  height: 11px;
  background-color: #fff;
}
