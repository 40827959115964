body {
  margin: 0;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url("../images/bg.png") repeat;
  opacity: 0.85;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity=85);
  z-index: 9999;
}
.login-view,
.registration-view,
.password-reset-view,
.password-recovery-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login-view h1.title,
.registration-view h1.title,
.password-reset-view h1.title,
.password-recovery-view h1.title {
  font-size: 20px;
  text-align: center;
  font-weight: normal;
}
.login-view .authentication,
.registration-view .authentication,
.password-reset-view .authentication,
.password-recovery-view .authentication,
.login-view .password-recovery,
.registration-view .password-recovery,
.password-reset-view .password-recovery,
.password-recovery-view .password-recovery {
  position: relative;
  z-index: 10000;
  width: 415px;
  margin: 5px auto;
}
.login-view .authentication label,
.registration-view .authentication label,
.password-reset-view .authentication label,
.password-recovery-view .authentication label,
.login-view .password-recovery label,
.registration-view .password-recovery label,
.password-reset-view .password-recovery label,
.password-recovery-view .password-recovery label {
  cursor: default;
}
.login-view .info,
.registration-view .info,
.password-reset-view .info,
.password-recovery-view .info {
  font-size: 16px;
  text-align: center;
}
.login-view .info,
.registration-view .info,
.password-reset-view .info,
.password-recovery-view .info,
.login-view .error-shared,
.registration-view .error-shared,
.password-reset-view .error-shared,
.password-recovery-view .error-shared {
  margin-top: 2rem;
}
.login-view .error-login-or-email,
.registration-view .error-login-or-email,
.password-reset-view .error-login-or-email,
.password-recovery-view .error-login-or-email,
.login-view .error-shared,
.registration-view .error-shared,
.password-reset-view .error-shared,
.password-recovery-view .error-shared {
  color: #f00;
  text-align: center;
}
.login-view .error,
.registration-view .error,
.password-reset-view .error,
.password-recovery-view .error {
  color: #f00;
  text-align: center;
  margin: 0.5rem 0 0.8rem;
}
.login-view .error button,
.registration-view .error button,
.password-reset-view .error button,
.password-recovery-view .error button {
  color: #2d3236;
  display: inline;
  float: none;
  font-size: 13px;
  font-size: 1.3rem;
  margin-top: 0.2rem;
}
.login-view .info,
.registration-view .info,
.password-reset-view .info,
.password-recovery-view .info {
  color: #2d3236;
}
.login-view .current-login,
.registration-view .current-login,
.password-reset-view .current-login,
.password-recovery-view .current-login {
  font-size: 15px;
  font-size: 1.5rem;
  margin-bottom: 0px;
}
.login-view form,
.registration-view form,
.password-reset-view form,
.password-recovery-view form {
  background: #eaeaea;
  padding: 25px;
  padding-top: 40px;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  position: relative;
}
.login-view form label,
.registration-view form label,
.password-reset-view form label,
.password-recovery-view form label {
  font-size: 13px;
  font-size: 1.3rem;
}
.login-view form input,
.registration-view form input,
.password-reset-view form input,
.password-recovery-view form input {
  display: block;
  height: 4rem;
  width: 100%;
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 1.5rem;
}
.login-view form input:invalid,
.registration-view form input:invalid,
.password-reset-view form input:invalid,
.password-recovery-view form input:invalid {
  background: #fff;
}
.login-view form input.validation-error,
.registration-view form input.validation-error,
.password-reset-view form input.validation-error,
.password-recovery-view form input.validation-error {
  border: 1px solid #f00;
}
.login-view form input[type=submit],
.registration-view form input[type=submit],
.password-reset-view form input[type=submit],
.password-recovery-view form input[type=submit] {
  margin: 1rem auto 0;
  padding: 0 7.5rem;
  width: 100%;
}
.login-view form input[type=submit].loading,
.registration-view form input[type=submit].loading,
.password-reset-view form input[type=submit].loading,
.password-recovery-view form input[type=submit].loading {
  background: url("../images/loader.gif") 10px center no-repeat;
}
.login-view form input[type=submit].sign-up,
.registration-view form input[type=submit].sign-up,
.password-reset-view form input[type=submit].sign-up,
.password-recovery-view form input[type=submit].sign-up {
  padding: 0 4rem;
}
.login-view form .forgot-password,
.registration-view form .forgot-password,
.password-reset-view form .forgot-password,
.password-recovery-view form .forgot-password,
.login-view form .forgot-login-switch,
.registration-view form .forgot-login-switch,
.password-reset-view form .forgot-login-switch,
.password-recovery-view form .forgot-login-switch {
  color: #808080;
  font-size: 12px;
  font-size: 1.2rem;
}
.login-view form .logo,
.registration-view form .logo,
.password-reset-view form .logo,
.password-recovery-view form .logo {
  display: block;
  width: 100%;
  background: url("../images/logo_new.png") top left no-repeat;
  text-indent: -9999px;
  background-position: center center;
  position: relative;
  top: -20px;
  height: 100px;
}
.login-view form.password-reset input,
.registration-view form.password-reset input,
.password-reset-view form.password-reset input,
.password-recovery-view form.password-reset input {
  margin-bottom: 13px;
}
.login-view button,
.registration-view button,
.password-reset-view button,
.password-recovery-view button {
  color: #fff;
  float: right;
  margin-top: 0.8rem;
}
.login-view button:focus,
.registration-view button:focus,
.password-reset-view button:focus,
.password-recovery-view button:focus {
  outline: none;
}
.login-view button.forgot-password,
.registration-view button.forgot-password,
.password-reset-view button.forgot-password,
.password-recovery-view button.forgot-password {
  float: none;
}
.login-view button.registration-view-switch,
.registration-view button.registration-view-switch,
.password-reset-view button.registration-view-switch,
.password-recovery-view button.registration-view-switch,
.login-view button.login-switch,
.registration-view button.login-switch,
.password-reset-view button.login-switch,
.password-recovery-view button.login-switch,
.login-view button.user-switch,
.registration-view button.user-switch,
.password-reset-view button.user-switch,
.password-recovery-view button.user-switch,
.login-view button.back-to-login-switch,
.registration-view button.back-to-login-switch,
.password-reset-view button.back-to-login-switch,
.password-recovery-view button.back-to-login-switch {
  float: left;
}
.login-view button.language-select,
.registration-view button.language-select,
.password-reset-view button.language-select,
.password-recovery-view button.language-select {
  padding-left: 10px;
  width: 90px;
  line-height: 30px;
  height: 30px;
  background-color: #eaeaea;
  border: 1px;
  color: #000;
  text-align: left;
  position: relative;
}
.login-view button.language-select:after,
.registration-view button.language-select:after,
.password-reset-view button.language-select:after,
.password-recovery-view button.language-select:after {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.login-view button.language-select:after,
.registration-view button.language-select:after,
.password-reset-view button.language-select:after,
.password-recovery-view button.language-select:after {
  float: right;
  margin-right: 10px;
}
.login-view .footer,
.registration-view .footer,
.password-reset-view .footer,
.password-recovery-view .footer {
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  margin: 0 25px;
}
.login-view .footer span,
.registration-view .footer span,
.password-reset-view .footer span,
.password-recovery-view .footer span {
  float: left;
  margin-top: 0.8rem;
}
.login-view .footer p,
.registration-view .footer p,
.password-reset-view .footer p,
.password-recovery-view .footer p {
  margin-top: 8px;
}
.login-view .footer a,
.registration-view .footer a,
.password-reset-view .footer a,
.password-recovery-view .footer a {
  color: #fff;
  text-decoration: none;
}
.login-view .footer a:hover,
.registration-view .footer a:hover,
.password-reset-view .footer a:hover,
.password-recovery-view .footer a:hover {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.login-view .footer select.language-select,
.registration-view .footer select.language-select,
.password-reset-view .footer select.language-select,
.password-recovery-view .footer select.language-select {
  float: right;
  margin-top: 0.8rem;
}
.login-view .copyright-notice,
.registration-view .copyright-notice,
.password-reset-view .copyright-notice,
.password-recovery-view .copyright-notice {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
}
.login-view .copyright-notice,
.registration-view .copyright-notice,
.password-reset-view .copyright-notice,
.password-recovery-view .copyright-notice,
.login-view .copyright-notice a,
.registration-view .copyright-notice a,
.password-reset-view .copyright-notice a,
.password-recovery-view .copyright-notice a {
  color: #ced6dd;
}
.login-view .copyright-notice a,
.registration-view .copyright-notice a,
.password-reset-view .copyright-notice a,
.password-recovery-view .copyright-notice a {
  margin-left: 1rem;
}
.user-view {
  background-color: #eaeaea;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  background: #eaeaea;
  position: fixed;
  top: 45px;
  z-index: 999;
  width: 345px;
  right: 5px;
  padding: 15px;
}
.user-view button.danger,
.user-view input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
.user-view button.danger:hover:not(:disabled),
.user-view input.danger:hover:not(:disabled) {
  background-color: rgba(242,222,222,0.5);
}
.user-view button.danger[disabled],
.user-view input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
.user-view button.finish,
.user-view input.finish {
  background-color: #c9dac2;
  color: #036707;
}
.user-view button.finish:hover:not(:disabled),
.user-view input.finish:hover:not(:disabled) {
  background-color: rgba(201,218,194,0.5);
}
.user-view button.finish[disabled],
.user-view input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
.user-view button.loading,
.user-view input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
.user-view .arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #eaeaea;
  margin: -25px -5px 0 auto;
}
.user-view .last-sync,
.user-view .info {
  margin-top: 1rem;
}
.user-view form {
  margin-top: 1rem;
}
.user-view form input[type=password] {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 0 1.5rem;
  height: 3rem;
  width: 100%;
}
.user-view form input[type=password]:invalid {
  background: #fff;
}
.user-view form .error {
  color: #f00;
}
.user-view button.log-out {
  background-color: #fbeed5;
  color: #9e7c00;
  float: left;
}
.user-view button.log-out:hover:not(:disabled) {
  background-color: rgba(251,238,213,0.5);
}
.user-view button.log-out[disabled] {
  background-color: rgba(251,238,213,0.24);
  color: rgba(158,124,0,0.24);
}
.user-view input.log-in {
  background-color: #d2dfed;
  color: #1a008d;
  float: right;
}
.user-view input.log-in:hover:not(:disabled) {
  background-color: rgba(210,223,237,0.5);
}
.user-view input.log-in[disabled] {
  background-color: rgba(210,223,237,0.24);
  color: rgba(26,0,141,0.24);
}
.user-view button.log-out,
.user-view input.log-in {
  font-size: 14px;
  font-size: 1.4rem;
  width: 150px;
  margin-top: 1rem;
  padding: 0.5rem 2.5rem;
  border: 1px solid #bfbfbf;
}
.user-view button.log-out.loading,
.user-view input.log-in.loading {
  background: url("../images/loader.gif") 10px center no-repeat;
}
label.tos-acceptance {
  cursor: default;
}
label.tos-acceptance input {
  display: inline-block;
  width: 1.25em;
  height: 1.25em;
  padding: 0;
  vertical-align: middle;
  margin-right: 6px;
}
label.tos-acceptance a {
  color: #808080;
  text-decoration: none;
}
