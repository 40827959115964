.popup-menu {
  display: none;
  position: fixed;
  z-index: 1010;
  background: #f6f6f6;
  border: 1px solid #b5b5b5;
  padding: 0;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
}
.popup-menu span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
.popup-menu ul {
  position: relative;
}
.popup-menu ul li {
  background: #f6f6f6;
}
.popup-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  min-width: 140px;
}
.popup-menu ul li {
  float: left;
  clear: left;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  line-height: 36px;
  background: #f6f6f6;
}
.popup-menu ul li.disabled {
  cursor: default;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.popup-menu ul li:hover {
  background: #d2dfed;
}
.popup-menu ul li label {
  display: block;
}
.popup-menu ul li.active {
  background: #fff;
}
.popup-menu ul li.submenu {
  border-top: 1px solid #dfdfdf;
}
.popup-menu ul li.submenu:after {
  font-family: 'gdt';
  content: '\e019';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
.popup-menu ul li.submenu:after {
  float: right;
  line-height: 36px;
}
.popup-menu ul li.submenu span.text {
  float: left;
}
.popup-menu ul li span.checkbox {
  display: inline-block;
  width: 25px;
}
.popup-menu ul li span.text {
  padding-left: 25px;
}
.popup-menu ul li span.link {
  padding-left: 20px;
}
.popup-menu ul li.current {
  background: #ddeaf9;
}
.popup-menu ul.settings li,
.popup-menu ul.submenu-list li {
  font-size: 14px;
}
.popup-menu ul.submenu-list li {
  max-width: 280px;
  white-space: normal;
  line-height: 30px;
}
.popup-menu ul.submenu-list li .checkbox {
  float: left;
}
.popup-menu ul.submenu-list li .checkbox-text {
  overflow: hidden;
  line-height: 30px;
  display: block;
}
