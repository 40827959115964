.scope-view .scope-table {
  max-width: 1500px;
}
.scope-view .scope-table td {
  padding: 4px;
  border: none;
}
.scope-view .scope-table .title-cell {
  width: 200px;
  text-align: left;
}
.scope-view .scope-table .value-cell {
  padding-bottom: 10px;
  padding-right: 10px;
}
.scope-view .scope-table .value-cell textarea {
  width: 100%;
  min-height: 75px;
}
.scope-view .scope-table .description-cell {
  width: 33%;
  border: 1px solid #fff;
  color: #a9a9a9;
  font-size: 14px;
}
.scope-view .scope-table div[contenteditable=true] {
  border: 1px solid #bfbfbf;
  min-height: 60px;
}
.questions-initial-draft,
.questions-moderation {
  font-size: 14px;
  min-width: 920px;
}
.questions-initial-draft .standard-list li.expanded,
.questions-moderation .standard-list li.expanded,
.questions-initial-draft input,
.questions-moderation input {
  font-size: 14px;
}
.questions-initial-draft li.expanded .separated,
.questions-moderation li.expanded .separated {
  padding-left: 0;
  margin-left: 4px;
}
.questions-initial-draft li.management input,
.questions-moderation li.management input {
  width: 20%;
}
.questions-initial-draft li.diagnostic input,
.questions-moderation li.diagnostic input {
  width: 16%;
}
.questions-initial-draft li.diagnostic-comparator input,
.questions-moderation li.diagnostic-comparator input {
  width: 12%;
}
.questions-initial-draft li .caption:hover div,
.questions-moderation li .caption:hover div {
  text-decoration: none;
  cursor: default;
}
.questions-brainstorming,
.questions-rating {
  font-size: 14px;
}
.questions-brainstorming li,
.questions-rating li {
  border-right: 1px solid #bfbfbf;
}
.questions-brainstorming li.initial:before {
  background-color: #c9dac2;
}
.questions-rating li.completed:before {
  background-color: #c9dac2;
}
.questions-rating li.new:before {
  background-color: #fbeed5;
}
.questions-proposal {
  font-size: 14px;
}
.questions-proposal tr.accepted {
  background-color: #c9dac2;
}
.questions-proposal tr.mentioned {
  background-color: #fbeed5;
}
.questions-proposal tr.rejected {
  background-color: #f2dede;
}
.questions-proposal td {
  padding: 3px 5px;
}
.questions-proposal td:not(:first-child) {
  text-align: center;
}
.questions-proposal td:not(:first-child) label {
  display: inline-block;
  width: 100%;
}
.questions-proposal td,
.questions-proposal th {
  padding: 10px;
  width: 9%;
}
.questions-proposal td:first-child,
.questions-proposal th:first-child {
  width: auto;
}
.questions-approval {
  font-size: 14px;
}
.questions-approval tr.approved {
  background-color: #c9dac2;
}
.questions-approval tr.new {
  background-color: #fbeed5;
}
.questions-approval tr.not-approved {
  background-color: #f2dede;
}
.questions-approval td,
.questions-approval th {
  padding: 3px 5px;
}
.questions-approval td:first-child,
.questions-approval th:first-child {
  width: 30%;
}
.questions-finish {
  font-size: 14px;
}
div.information {
  margin-bottom: 20px;
}
.scope-toolbar {
  overflow: hidden;
}
.scope-toolbar .scope-step {
  cursor: pointer;
}
