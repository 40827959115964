table.diagnostic-pooled-values {
  width: 400px;
}
table.diagnostic-pooled-values.comparatorTest {
  width: 800px;
}
table.diagnostic-pooled-values tr {
  height: 20px;
}
table.diagnostic-pooled-values td {
  background-color: #e9e9e9;
  line-height: 20px;
  padding: 2px 3px;
}
table.diagnostic-pooled-values .label-cell {
  cursor: default;
  text-transform: capitalize;
}
table.diagnostic-pooled-values .value-cell {
  background-color: transparent;
  cursor: pointer;
}
table.diagnostic-pooled-values .value-cell:hover {
  background-color: #ddeaf9;
}
table.diagnostic-pooled-values .dx-tests td {
  cursor: default;
  background-color: #dedede;
}
