.team-members-view {
  min-width: 910px;
}
.team-members-view .ellipsis-wrapper {
  position: relative;
}
.team-members-view .caption a:hover:after {
  position: absolute;
  top: 0;
  content: '';
  width: 16px;
  height: 12px;
  margin-left: 5px;
  background: url("../images/mail.png") top left no-repeat;
  text-indent: -9999px;
}
.team-members-view li.expanded {
  height: 123px;
}
.coi-members-list .member-name {
  cursor: pointer;
}
.coi-members-list .member-status {
  float: left;
  margin-left: -10px;
  height: 100%;
  width: 5px;
}
.coi-members-list .member-status.not-sent {
  background: #999;
}
.coi-members-list .member-status.new {
  background: #ffb012;
}
.coi-members-list .member-status.mail-sent {
  background: #ffb012;
}
.coi-members-list .member-status.in-progress {
  background: #ffb012;
}
.coi-members-list .member-status.completed {
  background: #269f0c;
}
.coi-members-list .member-status.conflict {
  background: #dc3f3f;
}
.coi-members-list .member-status.connection-error {
  background: -webkit-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: -moz-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: -o-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: -ms-repeating-linear-gradient(155deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
  background: repeating-linear-gradient(-65deg, #999, #999 2px, #dc3f3f 1px, #dc3f3f 6px);
}
.coi-view .no-conflict {
  font-size: 15px;
  font-size: 1.5rem;
  padding: 5px;
}
.coi-view .coi-options {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bfbfbf;
}
.coi-view .coi-options input[type=radio] {
  margin: 0;
  margin-left: 5px;
  margin-top: 5px;
}
.coi-view .form-type {
  line-height: 40px;
}
.coi-view .form-type .type-name {
  text-transform: uppercase;
}
.coi-view .coi-member-header {
  cursor: pointer;
}
.coi-view .standard-list li {
  background-color: #ddd;
}
.coi-view .standard-list li:nth-child(odd) {
  background-color: #e9e9e9;
}
.coi-view table {
  border: 1px solid #bfbfbf;
}
.coi-view table th {
  font-weight: bold;
  background-color: #e9e9e9;
  border-bottom: 1px solid #bfbfbf !important;
}
.coi-view table th:first-child {
  min-width: 30%;
}
.coi-view table th,
.coi-view table td {
  padding: 10px 15px;
  border-width: 0;
  text-align: center;
  vertical-align: middle;
}
.coi-view table th:first-child,
.coi-view table td:first-child {
  text-align: left;
}
.coi-view table tr td {
  background-color: #e9e9e9;
  border-width: 0;
}
.coi-view table tr:nth-child(odd) td {
  background-color: #ddd;
}
#toolbar-container menu[type="toolbar"].coi-view {
  width: 30px;
}
.coi-input-view {
  font-size: 16px;
}
.coi-input-view .questions {
  display: inline;
  float: left;
  overflow: hidden;
  width: 53.71900826446281%;
  margin: 0 0.413223140495868%;
  *width: 53.71900826446281% -correction;
  *margin: 0 0.413223140495868% -correction;
}
.coi-input-view .questions tbody tr:hover {
  background: #ddeaf9;
}
.coi-input-view .questions th,
.coi-input-view .questions td {
  padding: 5px;
}
.coi-input-view .questions th:last-child,
.coi-input-view .questions td:last-child {
  width: 60px;
  text-align: center;
  vertical-align: middle;
}
.coi-input-view .answers {
  display: inline;
  float: left;
  overflow: hidden;
  width: 90.08264462809917%;
  margin: 0 0.413223140495868%;
  *width: 90.08264462809917% -correction;
  *margin: 0 0.413223140495868% -correction;
  padding-left: 15px;
}
.coi-input-view .coi-input-footer {
  padding-top: 20px;
  clear: both;
}
.coi-input-view .coi-input-footer a {
  padding-top: 1em;
  float: left;
}
.coi-input-view .buttons-block {
  text-align: center;
}
.questions-with-conflicts .questions table {
  background-color: #fff;
}
.questions-with-conflicts .questions table th {
  text-align: center;
  border-bottom-width: 2px;
}
.questions-with-conflicts .questions table tr td {
  font-size: 14px;
}
.questions-with-conflicts .questions table th,
.questions-with-conflicts .questions table tr td {
  padding: 10px;
}
.questions-with-conflicts .answers ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.questions-with-conflicts .answers ul li {
  margin-bottom: 15px;
}
