#list-box {
  display: none;
  position: fixed;
  z-index: 1010;
  background: #fff;
  border: 1px solid #bfbfbf;
  -webkit-box-shadow: 0 0 15px #565656;
  box-shadow: 0 0 15px #565656;
}
#list-box span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  right: 19px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
#list-box ul {
  position: relative;
}
#list-box ul li {
  background: #f6f6f6;
}
#list-box.compound-selection li {
  text-transform: lowercase;
}
#list-box.compound-selection li .uppercase {
  text-transform: uppercase;
}
#list-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
  min-width: 120px;
}
#list-box ul li {
  float: left;
  clear: left;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  line-height: 36px;
}
#list-box ul li.disabled {
  cursor: default;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#list-box ul li:hover {
  background: #d2dfed;
}
#list-box ul li label {
  display: block;
}
#list-box ul li.hline {
  border-top: 1px solid #bfbfbf;
}
#list-box ul .current {
  background: #ddeaf9;
}
#list-box ul .active {
  background: #d2dfed;
}
#list-box button:disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#list-box button:before {
  margin-right: 3px;
  vertical-align: middle;
}
#list-box a {
  color: #5395cf !important;
}
#list-box.options-selection {
  cursor: pointer;
  font-size: 14px;
  border-top: none;
  -webkit-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
}
#list-box.options-selection span.dot {
  display: none;
}
#list-box.options-selection ul li {
  background: #fff;
  line-height: 30px;
  padding: 0px 15px;
  text-transform: lowercase;
}
#list-box.options-selection ul li.current {
  background: #ddd;
}
#list-box.options-selection ul li:hover {
  background: #eaeaea;
}
#list-box.options-selection ul li .uppercase {
  text-transform: uppercase;
}
#list-box.options-selection ul li.clear-option {
  padding: 0px;
  cursor: pointer;
  text-align: center;
  color: #5c5d5f;
  background: #cdcdcd;
}
#list-box.options-selection ul li.clear-option:hover {
  background: #ddd;
}
