.box .help-box {
  padding: 0 10px;
}
.box .help-box span.close {
  display: block;
  float: none;
  width: 28px;
  height: 28px;
  line-height: 28px;
  position: absolute;
  background: #f6f6f6;
  text-align: center;
  top: 0;
  right: 0;
}
.box .help-box .help-content {
  padding: 0 10px;
  min-width: 400px;
  max-width: 550px;
}
.box .help-box .help-content p {
  margin-top: 5px;
}
