.tabs-view .tab-elems {
  position: relative;
  height: 42px;
}
.tabs-view .tab-elems .tab-elems-line {
  z-index: 1;
  height: 43px;
  border-bottom: 1px solid #bfbfbf;
}
.tabs-view .tab-elems .tab-elems-content {
  z-index: 2;
}
.tabs-view .tab-elems .tab-elems-line,
.tabs-view .tab-elems .tab-elems-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.tabs-view .tab-elems .tab-elem {
  padding: 0 10px;
  line-height: 40px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  color: #c4c4c4;
}
.tabs-view .tab-elems .tab-elem.current-tab {
  color: #2d3236;
  border: 1px solid #bfbfbf;
  background-color: #e9e9e9;
  border-bottom-color: #e9e9e9;
  font-weight: bold;
}
