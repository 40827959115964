.etd-templates-view .template-select-text {
  margin-right: 10px;
}
.etd-templates-view .block {
  padding: 5px;
  margin-bottom: 10px;
  background: #f6f6f6;
  border: 1px solid #bfbfbf;
}
.etd-templates-view .block-sections-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.etd-templates-view .block-sections-list li {
  height: initial;
  line-height: initial;
  padding-left: 10px;
  margin-bottom: 5px;
}
.etd-templates-view .block-sections-list li:first-child {
  height: initial;
  line-height: initial;
}
.etd-templates-view .block-sections-list .disabled {
  opacity: 0.3;
}
.etd-templates-view .section-checkbox input {
  margin-right: 4px;
}
.etd-templates-view .section-checkbox span.section-name .with-desc {
  text-transform: uppercase;
}
.etd-templates-view .section-desc {
  font-weight: bold;
  padding-left: 20px;
}
.etd-templates-view .template-name-block input {
  width: 100%;
}
.etd-templates-view .selected-template-block {
  padding: 5px;
  border: 1px solid #bfbfbf;
}
.etd-templates-view .selected-template-block .template-box .expanded {
  border: none;
  background-color: inherit;
  height: 100%;
  padding: 0;
}
.etd-templates-view .selected-template-block .template-box .expanded li {
  background-color: inherit;
  border: none;
}
.etd-templates-view .presentations-table {
  width: 100%;
}
.etd-templates-view .presentations-table .presentations-section-checkbox {
  line-height: 40px;
}
.etd-templates-view .presentations-table td {
  width: 33%;
}
.etd-templates-view .presentations-table td ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.etd-templates-view .presentations-table td ul li {
  height: initial;
  line-height: initial;
  padding-left: 10px;
  margin-bottom: 5px;
}
.etd-templates-view .presentations-table td ul li:first-child {
  height: initial;
  line-height: initial;
}
.etd-templates-view .presentations-table td ul .disabled {
  opacity: 0.3;
}
