.insert-options label {
  display: block;
  padding: 5px 15px;
}
.insert-options label input#file {
  display: none;
}
.insert-options label input {
  position: relative;
  top: 3px;
}
.insert-options label button.file {
  line-height: 25px;
  padding: 0 5px;
}
.error {
  color: #f00;
  padding: 0 15px;
}
.hidden {
  visibility: hidden;
}
#recommendations {
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  font-size: 14px;
}
#recommendations .recommendations-header {
  margin-bottom: 50px;
}
#recommendations .recommendations-header table {
  width: 100%;
  border: 1px solid #bfbfbf;
  background: #dedede;
  font-size: 14px;
}
#recommendations .recommendations-header table .header {
  font-size: 20px;
}
#recommendations .recommendations-header table .question {
  font-size: 16px;
}
#recommendations .recommendations-header table tr td {
  padding: 5px;
  display: table-cell;
}
#recommendations .recommendations-header table tr td.first {
  padding-left: 20px;
  width: 180px;
}
#recommendations .recommendations-header table tr td.wider {
  width: 165px;
}
#recommendations .recommendations-header table tr td span {
  display: block;
  height: 100%;
}
#recommendations .recommendations-header table tr td span.section-name {
  font-weight: bold;
}
#recommendations .recommendations-header table tr td span.outcomes-list {
  min-width: 427px;
}
#recommendations .recommendations-header table tr td span.background-section {
  padding-right: 15px;
}
#recommendations .recommendations-header table tr td div {
  display: block;
  width: 100%;
  line-height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}
#recommendations .recommendations-header table tr td div p {
  margin: 0;
}
#recommendations .recommendations-header table tr td div ul {
  margin: 0;
}
#recommendations .recommendations-header table tr td div.rows-1 {
  height: 22px;
}
#recommendations .recommendations-header table tr td div.rows-2 {
  min-height: 44px;
}
#recommendations .recommendations-header table tr td div.rows-6 {
  height: 132px;
}
#recommendations .recommendations-header table tr td div.rows-max {
  height: 374px;
}
#recommendations .recommendations-header table tr td div[contenteditable] {
  background-color: #fff;
}
#recommendations .recommendations-header table tr td select {
  background-color: #fff;
}
#recommendations .recommendations-header table tr td input {
  width: 100%;
}
#recommendations .recommendations-header table tr td h1 {
  margin: 0;
  font-size: 20px;
  cursor: pointer;
}
#recommendations .recommendations-header table tr td h1.expanded:before {
  padding-right: 5px;
  font-family: gdt;
  content: '\e018';
  vertical-align: top;
}
#recommendations .recommendations-header table tr td h1.collapsed:before {
  padding-right: 5px;
  font-family: gdt;
  content: '\e019';
  vertical-align: top;
}
#recommendations .recommendations-header table tr td.right-button {
  text-align: center;
  vertical-align: top;
  width: 40px;
  border-left: 1px solid #bfbfbf;
}
#recommendations .judgement-table {
  table-layout: fixed;
}
#recommendations .judgement-table colgroup col.blank {
  width: 20px;
}
#recommendations .judgement-table colgroup col.criteria {
  width: 200px;
}
#recommendations .judgement-table colgroup col.judgements {
  width: 150px;
}
#recommendations .judgement-table colgroup col.research-evidences {
  width: auto;
}
#recommendations .judgement-table colgroup col.additional-considerations {
  width: 25%;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.blank {
  width: 20px;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.criteria {
  width: 17.5%;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.judgements {
  width: 27%;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.research-evidences {
  width: auto;
}
#recommendations .judgement-table colgroup.diagnostic-initial col.additional-considerations {
  width: 27%;
}
#recommendations .judgement-table > thead {
  background: #dedede;
  text-transform: uppercase;
}
#recommendations .judgement-table > thead tr {
  height: 34px;
  line-height: 34px;
}
#recommendations .judgement-table > thead h1 {
  padding-left: 20px;
  margin: 0;
  font-size: 20px;
  text-align: left;
  text-transform: none;
}
#recommendations .judgement-table > thead .headers th.blank,
#recommendations .judgement-table > thead .diagnostic-headers th.blank {
  border-right: none;
}
#recommendations .judgement-table > thead .headers th.research-evidences,
#recommendations .judgement-table > thead .diagnostic-headers th.research-evidences {
  width: auto;
}
#recommendations .judgement-table > thead .headers th.criteria,
#recommendations .judgement-table > thead .diagnostic-headers th.criteria {
  border-left: none;
}
#recommendations .judgement-table > thead .domain {
  height: 80px;
}
#recommendations .judgement-table > thead .domain span {
  left: -0.5em;
}
#recommendations .judgement-table > thead th.rotated {
  padding: 0;
  position: relative;
  overflow: hidden;
}
#recommendations .judgement-table > thead th.rotated span {
  display: block;
  position: absolute;
  white-space: nowrap;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  line-height: 22px;
  left: 0;
  top: 100%;
  text-align: center;
}
#recommendations .judgement-table > thead th.rotated.h85 {
  height: 85px;
}
#recommendations .judgement-table > thead th.rotated.h85 span {
  width: 85px;
}
#recommendations .judgement-table > thead th.rotated.h125 {
  height: 125px;
}
#recommendations .judgement-table > thead th.rotated.h125 span {
  width: 125px;
}
#recommendations .judgement-table > thead th.rotated.h130 {
  height: 130px;
}
#recommendations .judgement-table > thead th.rotated.h130 span {
  width: 150px;
}
#recommendations .judgement-table > thead th.rotated.h170 {
  height: 170px;
}
#recommendations .judgement-table > thead th.rotated.h170 span {
  width: 170px;
}
#recommendations .judgement-table > thead th.rotated.h180 {
  height: 180px;
}
#recommendations .judgement-table > thead th.rotated.h180 span {
  width: 180px;
}
#recommendations .judgement-table > thead th.rotated.h200 {
  height: 200px;
}
#recommendations .judgement-table > thead th.rotated.h200 span {
  width: 200px;
}
#recommendations .judgement-table > thead th.rotated.h270 {
  height: 270px;
}
#recommendations .judgement-table > thead th.rotated.h270 span {
  width: 270px;
}
#recommendations .judgement-table > thead th.rotated.h360 {
  height: 360px;
}
#recommendations .judgement-table > thead th.rotated.h360 span {
  width: 360px;
}
#recommendations .judgement-table > thead th.rotated.h400 {
  height: 400px;
}
#recommendations .judgement-table > thead th.rotated.h400 span {
  width: 400px;
}
#recommendations .judgement-table > thead th.rotated.h490 {
  height: 490px;
}
#recommendations .judgement-table > thead th.rotated.h490 span {
  width: 490px;
}
#recommendations .judgement-table > thead th.rotated.h710 {
  height: 710px;
}
#recommendations .judgement-table > thead th.rotated.h710 span {
  width: 710px;
}
#recommendations .judgement-table tbody > tr > td {
  padding: 5px;
  word-break: break-word;
}
#recommendations .judgement-table tbody > tr > td.rotated {
  background: #dedede;
  padding: 0;
  position: relative;
  overflow: hidden;
}
#recommendations .judgement-table tbody > tr > td.rotated span {
  display: block;
  position: absolute;
  white-space: nowrap;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  text-transform: uppercase;
  line-height: 22px;
  left: 0;
  top: 100%;
  text-align: center;
}
#recommendations .judgement-table tbody > tr > td.rotated.h85 {
  height: 85px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h85 span {
  width: 85px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h125 {
  height: 125px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h125 span {
  width: 125px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h130 {
  height: 130px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h130 span {
  width: 150px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h170 {
  height: 170px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h170 span {
  width: 170px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h180 {
  height: 180px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h180 span {
  width: 180px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h200 {
  height: 200px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h200 span {
  width: 200px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h270 {
  height: 270px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h270 span {
  width: 270px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h360 {
  height: 360px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h360 span {
  width: 360px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h400 {
  height: 400px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h400 span {
  width: 400px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h490 {
  height: 490px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h490 span {
  width: 490px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h710 {
  height: 710px;
}
#recommendations .judgement-table tbody > tr > td.rotated.h710 span {
  width: 710px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell,
#recommendations .judgement-table tbody > tr > td.judgement {
  padding-top: 10px;
  vertical-align: top;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label,
#recommendations .judgement-table tbody > tr > td.judgement label {
  display: block;
  line-height: 16px;
  margin-bottom: 12px;
  position: relative;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label input,
#recommendations .judgement-table tbody > tr > td.judgement label input,
#recommendations .judgement-table tbody > tr > td.domain-cell label span,
#recommendations .judgement-table tbody > tr > td.judgement label span {
  display: inline-block;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label input,
#recommendations .judgement-table tbody > tr > td.judgement label input,
#recommendations .judgement-table tbody > tr > td.domain-cell label span.checked-marker,
#recommendations .judgement-table tbody > tr > td.judgement label span.checked-marker {
  margin-left: 0;
  position: absolute;
  top: 50%;
  margin-top: -7px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label span,
#recommendations .judgement-table tbody > tr > td.judgement label span {
  margin-left: 20px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label.varies input,
#recommendations .judgement-table tbody > tr > td.judgement label.varies input,
#recommendations .judgement-table tbody > tr > td.domain-cell label.varies span.checked-marker,
#recommendations .judgement-table tbody > tr > td.judgement label.varies span.checked-marker {
  margin-top: -4px;
}
#recommendations .judgement-table tbody > tr > td.domain-cell label.special,
#recommendations .judgement-table tbody > tr > td.judgement label.special {
  margin-top: 0.5rem;
  padding-top: 0.7rem;
  border-top: 1px dotted #bfbfbf;
}
#recommendations .judgement-table tbody > tr > td.recommendations-editor img {
  width: 100%;
}
#recommendations .judgement-table tbody > tr > td.criteria-cell {
  position: relative;
  padding: 10px;
  background: #d2dfed;
}
#recommendations .judgement-table tbody > tr > td .editor-container .top-buttons + div[contenteditable=true] {
  padding-top: 45px;
}
#recommendations .judgement-table tbody > tr > td div.question-title {
  font-weight: bold;
  border-top: solid 2px #000;
}
#recommendations .judgement-table tbody > tr > td table.short thead {
  border-bottom: solid 2px #000;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th {
  padding: 5px;
  background-color: #275c99;
  border-color: #000;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.effect-head-cell {
  background-color: #95b3d7;
  color: #000;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-1,
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-2,
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row th.prevalence-3 {
  background-color: #b8cce4;
  font-weight: normal;
  color: #000;
}
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row td.quality,
#recommendations .judgement-table tbody > tr > td table.short tr.outcome-diag-sof-row td.no-of-participants-studies {
  border-left: 1px solid #000;
  text-align: center;
  background: none;
}
#recommendations .judgement-table tbody > tr > td table.short thead > tr {
  background-color: #fff;
}
#recommendations .judgement-table tbody > tr > td table.short thead > tr th {
  padding: 5px;
  vertical-align: top;
  font-weight: bold;
}
#recommendations .judgement-table tbody > tr > td table.short tbody {
  border-bottom: solid 2px #000;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td {
  text-align: left;
  vertical-align: top;
  border-top: solid 1px #000;
  border-bottom: solid 1px #000;
  border-left: none;
  border-right: none;
  word-wrap: break-word;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.diagnostic-outcome-label,
#recommendations .judgement-table tbody > tr > td table.short tbody td.diagnostic-outcome-label.active {
  vertical-align: middle;
  text-align: left;
  background: none !important;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.diagnostic-effect {
  background-color: #dbe5f1;
  border-right: 1px solid #000;
  vertical-align: middle;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.complications-prevalences-desc,
#recommendations .judgement-table tbody > tr > td table.short tbody td.inconclusive-prevalences-desc {
  background-color: #dbe5f1;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.control-label {
  background-color: #e0e0e0;
  border-color: #000;
  font-weight: bold;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.corresponding-risk {
  background-color: #ebebeb;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.risk-with-control {
  background-color: none;
}
#recommendations .judgement-table tbody > tr > td table.short tbody td.risk-difference-with-intervention {
  background-color: #ededed;
}
#recommendations .judgement-table tbody > tr > td table.short .printout {
  border-top: solid 2px #000;
  border-bottom: solid 2px #000;
  border-left: none;
  border-right: none;
}
#recommendations .judgement-table tbody > tr > td table.short .blue-cell {
  text-align: left;
  color: #fff;
  font-weight: normal;
  background-color: #3271aa;
  border: 1px solid #efefef;
}
#recommendations .judgement-table tbody > tr > td table.short .grey-cell {
  text-align: left;
  border-top: 1px solid #efefef;
  border-right: 1px solid #efefef;
  background-color: #e0e0e0;
}
#recommendations .judgement-table tbody > tr .option-groups .upper-bound {
  margin-bottom: 10px;
}
#recommendations .judgement-table tbody > tr .option-groups .lower-bound {
  margin-top: 10px;
}
#recommendations .judgement-table tbody > tr .option-groups table.groups {
  margin: 5px;
}
#recommendations .judgement-table tbody > tr .option-groups table.groups .options-cell {
  border: none;
}
#recommendations .judgement-table tbody > tr .option-groups table.groups .options-cell .options {
  padding-right: 10px;
  border-right: 1px solid #bfbfbf;
}
#recommendations .judgement-table tbody > tr .option-groups table.groups .group-name-cell {
  text-align: left;
  border: none;
  word-break: initial;
}
#recommendations .judgement-table tbody > tr.confidence-benefits-harms {
  height: 160px;
}
#recommendations .judgement-table tbody > tr.values-and-preferences {
  height: 180px;
}
#recommendations .judgement-table tbody > tr.diagnostic-test-accuracy {
  height: 200px;
}
#recommendations .judgement-table tbody > tr.values {
  height: 50px;
}
#recommendations .judgement-table tbody > tr.resources {
  height: 90px;
}
#recommendations .judgement-table tbody > tr.acceptability {
  height: 125px;
}
#recommendations .judgement-table tbody > tr.feasibility {
  height: 125px;
}
#recommendations .judgement-table tbody > tr:nth-child(3) td:nth-child(4) table {
  margin: 35px 0;
}
#recommendations .judgement-table tbody > tr:nth-child(3) td:nth-child(4) table th:first-child {
  width: 30%;
}
#recommendations .judgement-table tbody > tr label {
  line-height: 1.8em;
}
#recommendations .judgement-table .test-accuracy-table-container table {
  width: auto;
}
#recommendations .judgement-table .test-accuracy-table-container table td {
  border: 1px solid #bfbfbf;
}
#recommendations .judgement-table .test-accuracy-summary span {
  display: block;
}
#recommendations .judgement-table table {
  table-layout: fixed;
  margin-bottom: 10px;
  text-align: center;
}
#recommendations .judgement-table table th {
  font-size: 0.8em;
}
#recommendations .judgement-table table th,
#recommendations .judgement-table table td {
  vertical-align: middle;
}
#recommendations .judgement-table .dx-inner-table thead {
  background-color: #d2dfed;
}
#recommendations .judgement-table .dx-inner-table td:first-child {
  text-align: left;
}
#recommendations .judgement-table .dx-inner-table td {
  font-size: 0.8em;
}
#recommendations .judgement-table .dx-inner-table .abs-difference-row {
  background-color: #e9e9e9;
}
#recommendations .judgement-table .dx-inner-table .quality-sign {
  font-size: 1.2em;
}
#recommendations .judgement-table table.confidence-judgement {
  margin-top: 20px;
}
#recommendations .judgement-table table.confidence-judgement th:first-child {
  width: 30%;
}
#recommendations .judgement-table .judgement-table-asterisks {
  margin: 5px 0 25px;
}
#recommendations .judgement-table .judgement label.varies {
  margin-top: 0.5rem;
  padding-top: 0.7rem;
  border-top: 1px dotted #bfbfbf;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance th:first-child {
  width: 50%;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance th,
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance td {
  border: 1px solid #bfbfbf;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-relative-importance td:nth-child(2) > input {
  width: 40px;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof th:first-child {
  width: 35%;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof th,
#recommendations .judgement-table .benefits-harms table.research-evidence-sof td {
  border: 1px solid #bfbfbf;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof .denominator {
  text-decoration: underline;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof .denominator:hover {
  background-color: #9bbde0;
  cursor: pointer;
}
#recommendations .judgement-table .benefits-harms table.research-evidence-sof .choose-outcomes {
  width: 100%;
  background-color: #9bbde0;
}
#recommendations .judgement-table table.yes-no-table {
  width: 50%;
}
#recommendations .judgement-table .printout {
  background-color: #fff;
  text-align: left;
}
#recommendations .judgement-table table.outcomes-table.short {
  font-size: 0.8em;
}
#recommendations .judgement-table table.outcomes-table.short .content {
  min-height: inherit;
  background: inherit;
  border: none;
}
#recommendations .judgement-table table.outcomes-table.short td {
  cursor: default !important;
}
#recommendations .judgement-table table.outcomes-table.short tr.outcome-diag-sof-row,
#recommendations .judgement-table table.outcomes-table.short .diagnostic-outcome-label {
  width: auto;
}
#recommendations .judgement-table table.outcomes-table.short .no-of-participants-studies {
  width: 15%;
}
#recommendations .judgement-table table.outcomes-table.short .quality {
  width: 15%;
}
#recommendations .judgement-table table.outcomes-table.short .diagnostic-effect {
  width: 8%;
}
#recommendations .judgement-table table.outcomes-table.short .comments {
  width: 25%;
}
#recommendations .recommendation-table td,
#recommendations .recommendation-table-static td {
  padding: 5px;
  text-align: center;
}
#recommendations .recommendation-table caption,
#recommendations .recommendation-table-static caption {
  border: 1px solid #bfbfbf;
  border-bottom: none;
  text-align: left;
  background-color: #dedede;
}
#recommendations .recommendation-table caption h1,
#recommendations .recommendation-table-static caption h1,
#recommendations .recommendation-table caption h2,
#recommendations .recommendation-table-static caption h2 {
  margin: 0;
  padding: 5px;
}
#recommendations .recommendation-table caption h1,
#recommendations .recommendation-table-static caption h1 {
  font-size: 20px;
  font-size: 2rem;
  border-bottom: 1px solid #bfbfbf;
}
#recommendations .recommendation-table > tr > td:first-child,
#recommendations .recommendation-table-static > tr > td:first-child {
  text-align: left;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  width: 18%;
}
#recommendations .recommendation-table td:first-child {
  padding: 10px;
  background: #d2dfed;
}
#recommendations .recommendation-table-static {
  margin-top: 25px;
}
#recommendations .recommendation-table-static td {
  width: auto;
}
#recommendations .recommendation-table-static td.align-left {
  text-align: left;
}
#recommendations .recommendation-table-static td.filled {
  font-weight: bold;
  padding: 10px;
  background: #dedede;
}
#recommendations .recommendation-table-static td.final-judgement {
  width: 16%;
}
#recommendations .recommendation-table-static .small {
  display: block;
  font-size: 0.65em;
}
#recommendations .radios-table td {
  border: none;
  word-break: break-word;
  text-align: center;
}
#recommendations .radios-table td.varies {
  border-left: #bfbfbf dotted 1px;
}
#recommendations .detailed-judgements-btn {
  padding: 0;
}
#recommendations .conclusions-options {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
#recommendations .conclusions-options label input[type="radio"] {
  display: block;
  margin: 0.5em auto;
}
#recommendations .conclusions-options .option-groups-cell {
  border: none;
}
#recommendations .conclusions-options .option-groups .upper-bound-cell {
  border: none;
  width: 150px;
}
#recommendations .conclusions-options .option-groups .lower-bound-cell {
  border: none;
  width: 150px;
}
#recommendations .conclusions-options .option-groups table.groups td {
  vertical-align: middle;
}
#recommendations .conclusions-options .option-groups table.groups .options-cell {
  border: none;
}
#recommendations .conclusions-options .option-groups table.groups .options-cell label {
  width: 55px;
  height: 20;
  display: inline-block;
}
#recommendations .conclusions-options .option-groups table.groups .options-cell label input {
  display: inline-block;
  margin: 0;
  margin-top: 2px;
}
#recommendations .conclusions-options .option-groups table.groups .options-cell label span {
  margin: 0;
  margin-left: 5px;
}
#recommendations .conclusions-options .option-groups table.groups .options-cell .options {
  white-space: nowrap;
  border-top: 1px solid #bfbfbf;
}
#recommendations .conclusions-options .option-groups table.groups .group-name-cell {
  border: none;
}
#recommendations .summary-of-judgements-header {
  background-color: #eaeaea;
  border: 1px solid #bfbfbf;
}
.detailed-judgements {
  width: 70%;
}
.detailed-judgements .section-name {
  text-transform: uppercase;
}
.detailed-judgements .criterion-description {
  margin-left: 5px;
  font-weight: bold;
}
.detailed-judgements .additional-infos .additional-info {
  margin-top: 20px;
}
.detailed-judgements .panel-discussion {
  margin-top: 30px;
}
.detailed-judgements .inner-label {
  display: block;
  font-weight: bold;
  margin-top: 30px;
}
.detailed-judgements .discussion-content {
  height: 65px;
  overflow-y: scroll;
  background-color: #fff;
}
.detailed-judgements .discussion-content p {
  margin: 1px;
}
.detailed-judgements .horizontal-radios {
  width: 100%;
  background-color: #fff;
  height: 65px;
}
.detailed-judgements .horizontal-radios td {
  text-align: center;
  vertical-align: middle;
}
.detailed-judgements .horizontal-radios td input[type=radio] {
  display: block;
  margin: 0.5em auto;
}
.detailed-judgements .horizontal-radios td label {
  display: block;
}
.detailed-judgements .horizontal-radios .additional-options-border {
  border-left: 1px dashed #bfbfbf;
}
.detailed-judgements .bottom-buttons {
  margin-top: 20px;
}
.detailed-judgements .add-resource-btn {
  margin-top: 10px;
  line-height: 30px;
}
.detailed-judgements .judgements-table-container {
  margin-top: 20px;
}
.detailed-judgements .judgements-table-container .judgements-table {
  width: 100%;
}
.detailed-judgements .judgements-table-container .judgements-table .judgements-table-header > tr > th {
  font-weight: normal;
}
.detailed-judgements .judgements-table-container .judgements-table .judgement-table-row > th,
.detailed-judgements .judgements-table-container .judgements-table .judgement-table-row > td {
  border: 1px solid #bfbfbf;
}
.detailed-judgements .judgements-table-container .judgements-table .buttons-cell {
  width: 30px;
  vertical-align: middle;
  text-align: center;
}
.detailed-judgements .judgements-table-container .judgements-table .resource-name {
  width: 30%;
  height: 100%;
}
.detailed-judgements .judgements-table-container .judgements-table .resource-name .resource-name-editor {
  width: 100%;
  height: 100%;
  margin: 5px auto;
}
@media print {
  #recommendations th,
  #recommendations caption {
    -webkit-print-color-adjust: exact;
  }
  #recommendations .content {
    border: none;
  }
}
