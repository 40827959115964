#toolbar-container .evidence-syntheses-toolbar {
  padding-right: 5px;
}
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"] {
  margin-left: 5px;
  display: block;
}
#toolbar-container .evidence-syntheses-toolbar menu[type="toolbar"].context-buttons button {
  width: auto;
}
