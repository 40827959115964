.not-migrated-data table td.label {
  width: 20%;
}
.not-migrated-data table td {
  padding: 3px;
}
.not-migrated-data button.done {
  background: #5c5d5f;
  float: right;
  margin: 5px 0;
  width: 90px;
  padding: 5px;
}
.not-migrated-data button.done span {
  padding: 3px;
}
