#header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #ddd;
  -webkit-box-shadow: 0 0 5px rgba(0,0,0,0.2);
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  overflow: hidden;
}
#header .wrapper {
  padding: 0 5px;
}
#header > a,
#header > button {
  white-space: nowrap;
}
#header .logo {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: normal;
  color: #b3b4b5;
  display: block;
  display: inline;
  float: left;
  overflow: hidden;
  width: 8.264462809917356%;
  margin: 0 0.413223140495868%;
  *width: 8.264462809917356% -correction;
  *margin: 0 0.413223140495868% -correction;
  width: 130px;
}
#header .logo a {
  background: url("../images/logo_new.png") top left no-repeat;
  text-indent: -9999px;
  background-position: left center;
  -webkit-background-size: 130px;
  -moz-background-size: 130px;
  background-size: 130px;
}
#header .update-installed-info {
  display: none;
  float: right;
  background-color: #cecece;
  padding: 0 15px;
}
#header .update-installed-info a.whats-new-link {
  display: inline;
  color: #5a6d8d;
  text-decoration: underline;
}
#header .update-installed-info,
#header .update-installed-info a {
  font-size: 14px;
  color: #5395cf !important;
}
#header .update-installed-info span {
  font-weight: bold;
}
#header .projects {
  font-size: 16px;
  padding-right: 10px;
  overflow: hidden;
}
#header .projects.opened .projects-menu {
  display: block;
  width: 50%;
  min-width: 750px;
}
#header .projects.opened .projects-menu .col-1 {
  line-height: 36px;
}
#header .projects.opened .title > a:before {
  font-family: 'gdt';
  content: '\e017';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header .projects.opened .title > a * {
  margin-left: 0.5rem;
}
#header .projects.opened .btn {
  height: 36px;
  line-height: 36px;
}
#header .projects .title {
  padding-left: 30px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#header .projects .title > a {
  display: inline;
}
#header .projects .title > a:before {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header .projects .title > a * {
  margin-left: 0.5rem;
}
#header .projects .title > a:before {
  font-size: 20px;
  padding-right: 10px;
}
#header .projects-menu {
  display: none;
  background-color: #eaeaea;
  -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.2);
  background-color: #fff;
  position: absolute;
  top: 52px;
  margin-left: -10px;
  z-index: 999;
}
#header .projects-menu button.danger,
#header .projects-menu input.danger {
  background-color: #f2dede;
  color: #9e0000;
}
#header .projects-menu button.danger:hover:not(:disabled),
#header .projects-menu input.danger:hover:not(:disabled) {
  background-color: rgba(242,222,222,0.5);
}
#header .projects-menu button.danger[disabled],
#header .projects-menu input.danger[disabled] {
  background-color: rgba(242,222,222,0.24);
  color: rgba(158,0,0,0.24);
}
#header .projects-menu button.finish,
#header .projects-menu input.finish {
  background-color: #c9dac2;
  color: #036707;
}
#header .projects-menu button.finish:hover:not(:disabled),
#header .projects-menu input.finish:hover:not(:disabled) {
  background-color: rgba(201,218,194,0.5);
}
#header .projects-menu button.finish[disabled],
#header .projects-menu input.finish[disabled] {
  background-color: rgba(201,218,194,0.24);
  color: rgba(3,103,7,0.24);
}
#header .projects-menu button.loading,
#header .projects-menu input.loading {
  background-image: url("../images/loader.gif");
  background-position: 7px center;
  background-repeat: no-repeat;
}
#header .projects-menu span.dot {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  background: #f6f6f6;
  width: 10px;
  height: 10px;
  top: -5px;
  left: 42px;
  position: absolute;
  border: 1px solid #b5b5b5;
}
#header .projects-menu> div {
  padding: 10px;
  position: relative;
  background: #fff;
}
#header .projects-menu ol {
  display: block;
  overflow-y: auto;
  width: 100%;
}
#header .projects-menu ol .caption {
  display: block;
  white-space: normal;
  padding-right: 5px;
  font-size: 16px;
}
#header .projects-menu ol .caption:hover div {
  text-decoration: underline;
}
#header .projects-menu menu {
  padding: 0;
  margin: 0;
  list-style: none;
  float: none;
  height: 40px;
}
#header .projects-menu menu label,
#header .projects-menu menu li {
  display: block;
  float: left;
  text-align: center;
  border-bottom: 1px solid #bfbfbf;
}
#header .projects-menu menu label {
  width: 19%;
  cursor: default;
}
#header .projects-menu menu li {
  width: 27%;
  border-left: 1px solid #bfbfbf;
}
#header .projects-menu menu li:hover {
  background: #fff;
}
#header a,
#header button {
  display: inline-block;
  height: 40px;
  width: 100%;
}
#header a,
#header button,
#header a:link,
#header button:link,
#header a:visited,
#header button:visited,
#header a:hover,
#header button:hover,
#header a:active,
#header button:active {
  color: inherit;
  text-decoration: none;
}
#header a.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
#header a:hover:not(.disabled):not(.caption) {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
#header menu.right {
  float: right;
  margin: 0;
  padding: 0;
}
#header menu.right button,
#header menu.right a {
  width: 40px;
  height: 37px;
  float: left;
  margin-right: 10px;
  outline: none;
}
#header menu.right .feedback:before,
#header menu.right .help:before,
#header menu.right .settings:before {
  font-size: 16px;
}
#header menu.right .feedback:before {
  font-family: 'gdt';
  content: '\e002';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu.right .feedback * {
  margin-left: 0.5rem;
}
#header menu.right .help:before {
  font-family: 'gdt';
  content: '\e003';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu.right .help * {
  margin-left: 0.5rem;
}
#header menu.right .settings:before {
  font-family: 'gdt';
  content: '\e001';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu.right .settings * {
  margin-left: 0.5rem;
}
#header menu.right .notifications {
  font-size: 20px;
  font-size: 2rem;
  padding-right: 0.5rem;
  line-height: 40px;
}
#header menu.right .notifications:before {
  font-family: 'gdt';
  content: '\e008';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu.right .notifications * {
  margin-left: 0.5rem;
}
#header menu.right .notifications.on:before {
  font-family: 'gdt';
  content: '\e007';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header menu.right .notifications.on * {
  margin-left: 0.5rem;
}
#header menu.right .connection {
  width: 10px;
  height: 10px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-top: 15px;
  background-color: #2d3236;
  border: 1px solid #24282b;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(45,50,54,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(45,50,54,0.5);
}
#header menu.right .connection.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #2d3236;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #2d3236;
}
#header menu.right .connection.disconnected {
  background-color: #c80000;
  border: 1px solid #a00000;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(200,0,0,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(200,0,0,0.5);
}
#header menu.right .connection.disconnected.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #c80000;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #c80000;
}
#header menu.right .connection.unauthorized {
  background-color: #f8bd24;
  border: 1px solid #dca107;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(248,189,36,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(248,189,36,0.5);
}
#header menu.right .connection.unauthorized.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #f8bd24;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #f8bd24;
}
#header menu.right .connection.connected {
  background-color: #87bb53;
  border: 1px solid #6c9b3d;
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(135,187,83,0.5);
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px rgba(135,187,83,0.5);
  -webkit-transition: background-color 350ms, box-shadow 700ms;
  -moz-transition: background-color 350ms, box-shadow 700ms;
  -o-transition: background-color 350ms, box-shadow 700ms;
  -ms-transition: background-color 350ms, box-shadow 700ms;
  transition: background-color 350ms, box-shadow 700ms;
}
#header menu.right .connection.connected.flash {
  -webkit-box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #87bb53;
  box-shadow: inset 0px 1px 0px 0px rgba(255,255,255,0.5), 0px 0px 2px 2px #87bb53;
}
#header .user-info {
  float: right;
  padding-left: 15px;
  border-left: 1px solid #bfbfbf;
  width: auto;
  display: inline;
}
#header .user-info:after {
  font-family: 'gdt';
  content: '\e018';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}
#header .user-info:after {
  padding: 0 10px;
  font-size: 20px;
}
#header #header-message {
  width: 100%;
  text-align: center;
  font-size: 16px;
}
#header #header-message > * {
  height: 25px;
  line-height: 25px;
  display: none;
}
#header #header-message a {
  display: inline;
  color: #5a6d8d;
  text-decoration: underline;
  height: 40px;
}
#header #header-message .info {
  background: #958509 url("../images/hm-bg-i.png") left top repeat-x;
}
#header #header-message .info,
#header #header-message .info a {
  color: #fff;
}
#header #header-message .warning {
  background: #950000 url("../images/hm-bg-w.png") left top repeat-x;
  color: #fff;
}
#header #header-message .warning .question-mark-sign {
  font-size: 14px;
  vertical-align: middle;
  font-weight: bold;
  border-width: 2px;
  border-color: #fff;
  height: 18px;
  width: 18px;
  margin-left: 5px;
  margin-bottom: 4px;
}
#header .standard-list li:first-child,
#header .standard-list li:last-child,
#header .standard-list li:only-child {
  height: 40px;
}
#header .standard-list li {
  height: 40px;
  line-height: 40px;
}
#header .standard-list li {
  display: block;
  border-left-width: 0;
  border-right-width: 0;
}
#header.alternate .logo {
  display: inline;
  float: left;
  overflow: hidden;
  width: 17.355371900826448%;
  margin: 0 0.413223140495868%;
  *width: 17.355371900826448% -correction;
  *margin: 0 0.413223140495868% -correction;
}
#header.alternate .projects.opened > a {
  background: url("../images/arrow-up.png") left center no-repeat;
  padding-left: 30px;
}
#header.alternate .projects > a {
  background: url("../images/arrow-down.png") left center no-repeat;
  padding-left: 30px;
}
