.webpage-view #webpage-content {
  width: 100%;
  border: none;
  background: #fff;
}
.webpage-view .buttons-block {
  margin-top: 5px;
}
.webpage-view .buttons-block a {
  cursor: pointer;
  display: inline-block;
}
.webpage-view .buttons-block a,
.webpage-view .buttons-block a:link,
.webpage-view .buttons-block a:visited,
.webpage-view .buttons-block a:hover,
.webpage-view .buttons-block a:active {
  color: inherit;
  text-decoration: none;
}
.webpage-view .buttons-block a:hover {
  opacity: 0.7;
}
