.outcome-edit-block {
  width: 100%;
  font-style: normal;
}
.outcome-edit-block label.disabled {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
}
.outcome-edit-block .outcome-toggleable-editor {
  position: relative;
  margin: 0;
  display: none;
  background: #d2dfed;
}
.outcome-edit-block .outcome-label-edit {
  text-align: left;
  background: #d2dfed;
  border-bottom: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-label-edit button.save {
  margin-top: 5px;
}
.outcome-edit-block .outcome-label-edit .label-input {
  margin-right: 30px;
  padding: 10px;
  border-right: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-label-edit .label-input input {
  width: 100%;
}
.outcome-edit-block .outcome-sort-delete {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  border-left: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-edit {
  padding-left: 10px;
  text-align: left;
  background: #d2dfed;
}
.outcome-edit-block .outcome-edit input[type=text] {
  height: 30px;
}
.outcome-edit-block .outcome-edit ul,
.outcome-edit-block .outcome-edit li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 25px;
}
.outcome-edit-block .outcome-edit ul input[type=radio],
.outcome-edit-block .outcome-edit li input[type=radio] {
  margin-top: 3px;
}
.outcome-edit-block .outcome-edit div.edit-container {
  padding-bottom: 10px;
}
.outcome-edit-block .outcome-edit div.left-side-edit {
  padding-bottom: 20px;
  padding-right: 10px;
  border-right: 1px solid #bfbfbf;
}
.outcome-edit-block .outcome-edit div.right-side-edit {
  padding-left: 15px;
}
.outcome-edit-block .outcome-edit div.follow-up-label {
  margin-top: 6px;
}
.outcome-edit-block .outcome-edit div.box-bordered {
  border: 1px solid #bfbfbf;
  padding: 10px;
  *zoom: 1;
}
.outcome-edit-block .outcome-edit div.box-bordered:before,
.outcome-edit-block .outcome-edit div.box-bordered:after {
  content: "";
  display: table;
}
.outcome-edit-block .outcome-edit div.box-bordered:after {
  clear: both;
}
.outcome-edit-block .outcome-edit div.box-bordered ul li input.follow-up-time-unit-input {
  width: 75%;
  display: inline-block;
}
.outcome-edit-block .outcome-edit div.box-bordered ul.horizontal li {
  width: 50%;
  float: left;
}
#follow-up-length-row .selection-list-value,
#follow-up-length-row .inline {
  display: inline-block;
}
#follow-up-length-row .inline {
  line-height: 30px;
}
#follow-up-length-row .selection-list-value,
#follow-up-length-row .short {
  width: 75px;
}
#follow-up-length-row .select-input-container input {
  width: 100px;
}
@media (max-width: 1600px) {
  #follow-up-length-row.ranged input {
    width: 50px;
  }
  #follow-up-length-row.ranged .selection-list-value {
    width: 65px;
  }
  #follow-up-length-row.ranged .select-input-container.other {
    width: 125px;
  }
}
