.tasks-page #todos-container {
  display: block;
  display: inline;
  float: left;
  overflow: hidden;
  width: 53.71900826446281%;
  margin: 0 0.413223140495868%;
  *width: 53.71900826446281% -correction;
  *margin: 0 0.413223140495868% -correction;
}
.tasks-page #todos-container .separated {
  padding-right: 50px;
}
.tasks-page #todos-container li.expanded {
  height: 80px;
}
.tasks-page #todos-container li input.title {
  width: 100%;
  margin-right: 100px !important;
}
.tasks-page #todos-container li a.title.done {
  text-decoration: line-through;
}
.tasks-page #todos-container .caption {
  padding-left: 40px;
}
.tasks-page #todos-container input[type=text] {
  margin-left: 40px;
}
.tasks-page #todos-container input[type=checkbox] {
  position: absolute;
  top: 15px;
  left: 15px;
}
.tasks-page #todos-container .details input[type=text] {
  margin-left: 5px;
}
.tasks-page #calendar-container {
  display: inline;
  float: left;
  overflow: hidden;
  width: 44.62809917355372%;
  margin: 0 0.413223140495868%;
  *width: 44.62809917355372% -correction;
  *margin: 0 0.413223140495868% -correction;
}
.tasks-page #calendar-container #calendar table.fc-header td {
  border: none;
}
